import autobind from 'core-decorators/lib/autobind';
import React from 'react';
import { StyleSheet, css } from 'aphrodite';


@autobind
class TopNavigation extends React.Component {
  state = {
    subLinks: false,
  };

  render() {
    var loc = window.location.pathname;
    var backgroundState;
    var title;
    if(loc === "/" || loc === "/domov" || loc === "/prometni-znaki" || loc === "/javno3" || loc === "/dopolni" || loc === "/obvestila" || loc === "/obvezno" || loc === "/pznaki"){
      backgroundState = styles.domov;
      title = "";
    } else if(loc === "/znaki"){
      title = "izdelki";
      backgroundState = styles.izdelki;
    } else if(loc === "/o-podjetju"){
      title = "o podjetju";
      backgroundState = styles.o_podjetju;
    } else if(loc === "/novice"){
      title = "novice";
      backgroundState = styles.novice;
    } else if(loc === "/kontakt"){
      title = "kontakt";
      backgroundState = styles.kontakt;
    } else if(loc === "/generator"){
      title = "naročilo po meri";
      backgroundState = styles.novice;
    } else if(loc === "/proizvodnja"){
      title = "proizvodnja";
      backgroundState = styles.proizvodnja;
    } else if(loc === "/znaki/galerija-izdelkov"){
      title = "izdelki";
      backgroundState = styles.galerija;
    } else if(loc === "/znaki/vertikalna-signalizacija"){
      title = "izdelki";
      backgroundState = styles.izdelki;
    } else if(loc === "/znaki/prometni-znaki" || loc === "/izdelki/prometni-znaki"){
      title = "izdelki";
      backgroundState = styles.izdelki;
    } else if(loc === "/znaki/horizontalna-signalizacija" || loc === "/izdelki/horizontalna-signalizacija"){
      title = "izdelki";
      backgroundState = styles.izdelki;
    } else if(loc === "/znaki/ostala-signalizacija" || loc === "/izdelki/ostala-signalizacija"){
      title = "izdelki";
      backgroundState = styles.izdelki;
    } else if(loc === "/znaki/prometna-oprema" || loc === "/izdelki/prometna-oprema"){
      title = "izdelki";
      backgroundState = styles.izdelki;
    } else if(loc === "/znaki/druga-oprema" || loc === "/izdelki/druga-oprema"){
      title = "izdelki";
      backgroundState = styles.izdelki;
    } else if(loc === "/znaki/katalogi" || loc === "/izdelki/katalogi"){
      title = "izdelki";
      backgroundState = styles.katalogi;
    } else if(loc === "/certifikati/certifikati"){
      title = "izdelki";
      backgroundState = styles.navodila;
    } else if(loc === "/znaki/navodila" || loc === "/izdelki/navodila"){
      title = "izdelki";
      backgroundState = styles.navodila;
    } else {
      backgroundState = styles.izdelki;
      title = window.location.pathname.split("/").join(" ");
    }
    
    return (
      <div onClick={this.props.onOpen} className={css(styles.wrapper, backgroundState)}>
        {window.location.pathname === "/" || window.location.pathname === "/domov" || window.location.pathname ===  "/prometni-znaki" || window.location.pathname ===  "/javno3" || window.location.pathname ===  "/dopolni" || window.location.pathname ===  "/obvestila" || window.location.pathname ===  "/obvezno" || window.location.pathname ===  "/pznaki" ?
          <div  className={css(styles.box)}>
            <div className={css(styles.text1)}>20</div>
            <div className={css(styles.text2)}>
              let obstoja, neprecenljivo  
              <span className={css(styles.bold)}> znanje </span> 
              in 
              <span className={css(styles.bold)}> skrbna </span> 
              izdelava.
            </div>
            <div className={css(styles.imgWrapper)}>
              <img alt="prometni znaki" src="../assets/icons/menuOpen.png" className={css(styles.img)} />
            </div>
          </div>
          :
          <h1 className={css(styles.box2)}>
            {title}
            <div className={css(styles.imgWrapper)}>
              <img alt="prometni znaki" src="../assets/icons/menuOpen.png" className={css(styles.img)} />
            </div>
          </h1>
        }
      </div>
    );
  }
}

const styles = StyleSheet.create({
  wrapper: {
    position:"relative",
    backgroundPosition: "50% 40%",
    backgroundSize:"cover",
    display:"flex",
    alignItems:"center",
    justifyContent: 'flex-start',
    width:"100%",
    transition:"0.4s",
    minHeight:260,
    maxHeight:"50vh",
  },
  domov:{
    backgroundImage: "url(../assets/pictures/navigation/domov.jpg)",
    height:"50vh",
    minHeight:300,
    backgroundPosition: "50% 100%",
    '@media (max-width: 800px)': {
      height:"40vh",
    },
  },
  izdelki:{
    backgroundImage: "url(../assets/pictures/navigation/izdelki.png)",
    height:260,
  },
  o_podjetju:{
    backgroundImage: "url(../assets/pictures/navigation/o_podjetju.png)",
    height:260,
  },
  novice:{
    backgroundImage: "url(../assets/pictures/navigation/novice.png)",
    height:260,
  },
  kontakt:{
    backgroundImage: "url(../assets/pictures/navigation/kontakt.png)",
    height:260,
  },
  proizvodnja:{
    backgroundImage: "url(../assets/pictures/navigation/proizvodnja.png)",
    height:260,
  },
  galerija:{
    backgroundImage: "url(../assets/pictures/navigation/galerija.png)",
    height:260,
  },
  katalogi:{
    backgroundImage: "url(../assets/pictures/navigation/katalogi.png)",
    height:260,
  },
  navodila:{
    backgroundImage: "url(../assets/pictures/navigation/katalogi.png)",
    height:260,
  },


  box:{
    marginLeft:"10%",
    '@media (max-width: 520px)': {
      width:"calc(100% - 60px)",
      margin:"0 auto",
    },
    '@media (max-width: 340px)': {
      width:"calc(100% - 40px)",
    },
  },
  box2:{
    width:"calc(100% - 100px)",
    margin:"0 auto",
    maxWidth:1000,
    display:"flex",
    alignItems:"flex-end",
    justifyContent:"flex-start",
    height:"calc(100% - 60px)",
    fontSize:60,
    color:"#fff",
    letterSpacing:2.7,
    fontWeight:300,
    '@media (max-width: 580px)': {
      width:"calc(100% - 60px)",
      fontSize:52,
    },
    '@media (max-width: 340px)': {
      width:"calc(100% - 40px)",
      fontSize:50,
    }
  },
  text1:{
    fontSize:100,
    fontWeight:300,
    color:"#fff",
    lineHeight:0.4,
    '@media (max-width: 430px)': {
      fontSize:80,
    },
    '@media (max-width: 370px)': {
      fontSize:60,
    },
  },
  text2:{
    color:"#fff",
    marginTop:30,
    width:433,
    fontWeight:100,
    fontSize:45,
    marginLeft:40,
    '@media (max-width: 800px)': {
      marginLeft: 0,
    },
    '@media (max-width: 490px)': {
      width:380,
      fontSize:40,
    },
    '@media (max-width: 430px)': {
      width:320,
      fontSize:33,
    },
    '@media (max-width: 370px)': {
      width:290,
      marginTop:20,
      fontSize:30,
    },
  },
  bold:{
    fontWeight:600,
  },
  imgWrapper:{
    display:"none",
    '@media (max-width: 1090px)': {
      cursor:"pointer",
      display:"block",
      position:"absolute",
      top:20,
      right:20,
    },
  },
  img:{
    width:40,
  },
  

});

export default TopNavigation;
