import autobind from 'core-decorators/lib/autobind';
import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import Helmet from "react-helmet";


import AboutSelection from "../selections/AboutSelection.js";

@autobind
class AboutPage extends React.Component {
  

  render() {
    return (
      
  <div style={{minWidth:300, position:"relative",overflowX:"hidden"}}>
  <Helmet>
      <title>Signaco - Prometni znaki</title>
      <meta name="description" content="Podjetje za prodajo prometnih znakov, izdelujemo prometne znake, nosilne drogove, portale, sidra ter ostalo komunalno in urbano opremo, izdelava: prometni znaki"/>
    </Helmet>
      <div className={css(styles.wrapper)}>
         <h1 className={css(styles.title)}>prometni znaki</h1>
         <div className={css(styles.infoWrapper1)}>
            <p className={css(styles.text)}>Podjetje Signaco d.o.o. se ukvarja s proizvodnjo in prodajo prometne signalizacije s ciljem zagotavljanja čim večje prometne varnosti. Eno izmed osnovnih vodil poslovanja je zagotavljanje visoke kakovosti izdelkov, ki bodo vzdržali zahtevne klimatske in druge fizikalne obremenitve v okolju. Naši prometni in drugi znaki so skladni z zakonodajnimi zahtevami s področja prometne signalizacije in prometne opreme na javnih cestah. Izdelani so iz korozijsko odpornih kakovostnih in okoljsko sprejemljivih materialov priznanih proizvajalcev.</p>
            <p className={css(styles.infoTitle)}>Poslovna politika temelji na naslednjih načelih:</p>
            
         </div>
         <div className={css(styles.infoWrapper2)}>
            <AboutSelection title="razvoj tehnologije" text="Stalno <b>razvijamo in izboljšujemo izdelke</b> in <b>tehnološke postopke</b>. Ob tem  zmanjšujemo negativne vplive na okolje, zaposlene in zunanje sodelavce. Stalni razvoj dosegamo s postavljanjem merljivih ciljev na vseh ključnih področjih delovanja." />
            <AboutSelection title="skrb za okolje" text="Na področju okolja je naša usmeritev stalno <b>zmanjševanje onesnaževanja okolja</b> s poudarkom na preventivnem delovanju. Skrbimo za <b>optimalno izrabo vseh repromaterialov</b>, za skrbno delo z nevarnimi snovmi ter za varčevanje z energijo v vseh fazah poslovanja." />
            <AboutSelection title="zdravje zaposlenih" text="Na področju varnosti in zdravja pri delu delujemo <b>preventivno</b>. Veliko pozornost posvečamo ozaveščanju zaposlenih za <b>ohranjanje zdravja</b> ter za spoštovanje navodil varnega in zdravega dela." />
            <AboutSelection title="V koraku s časom" text="Redno spremljamo in izpolnjujemo zakonodajne zahteve, tehnične standarde in druge morebitne dodatne zahteve in dobre prakse, vezane na našo dejavnost, tehnološke naprave in izdelke." />
            <AboutSelection title="dobro delovno okolje" text="Spodbujamo zaposlene za <b>aktivno sodelovanje</b> pri razvoju podjetja. Skrbimo za njihovo <b>usposabljanje</b> za <b>kakovostno delo</b>, za okoljsko odgovorno ravnanje ter za varno in zdravo delo.</div>" />
            <AboutSelection title="dobri odnosi" text="Aktivno sodelujemo s <b>kupci, dobavitelji, kooperanti, zunanjimi izvajalci</b> in drugimi zainteresiranimi strankami." />  
         </div>

         {/*<div className={css(styles.title)}>Certifikati</div>
         <div className={css(styles.infoWrapper3)}>
            <div className={css(styles.text)}></div>
         </div>
            <div className={css(styles.wrapper1)}>
              <div className={css(styles.wrapperFlex1)}>
                <span className={css(styles.flexChild1)} ><img className={css(styles.img1)} src="../assets/certificat/cer1.jpg" /></span>
                <span className={css(styles.flexChild1)} ><img className={css(styles.img1)} src="../assets/certificat/cer2.jpg" /></span>
                <span className={css(styles.flexChild1)} ><img className={css(styles.img1)} src="../assets/certificat/cer3.jpg" /></span>
                <span className={css(styles.flexChild1)} ><img className={css(styles.img1)} src="../assets/certificat/cer4.jpg" /></span>
                <span className={css(styles.flexChild1)} ><img className={css(styles.img1)} src="../assets/certificat/cer5.jpg" /></span>
                <span className={css(styles.flexChild1)} ><img className={css(styles.img1)} src="../assets/certificat/cer6.jpg" /></span>
                <span className={css(styles.flexChild1)} ><img className={css(styles.img1)} src="../assets/certificat/cer7.jpg" /></span>
               
          
              </div>
            </div>*/}
   


  </div>

 
     



          
</div>
);
}
}

const styles = StyleSheet.create({
  wrapper1: {
    margin:"40px auto",

  },
  wrapperFlex1: {
    display:"flex",
    flexWrap:"wrap",
    justifycontent: "flex-start",


  },
  flexChild1: {
    flexBasis:"100",
    flexGrow: 2,
    height:130,
    margin:10,
    alignSelf: "center",

  },
  img1: {
    height:130,
    boxShadow: "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)",
  },
  wrapper:{
      maxWidth: 1000,
      margin: "50px auto",
      marginBottom:80,
      width: "calc(100% - 100px)",
      '@media (max-width: 580px)': {
        width: "calc(100% - 60px)",
      },
      '@media (max-width: 340px)': {
        width: "calc(100% - 40px)",
      },
   },
   title:{
      fontSize: 40,
      color: "#838383",
      letterSpacing: 1.8,
      fontWeight: 300,
    },
   infoWrapper1:{
      marginLeft: 30,
      paddingTop: 30,
      marginBottom: 20,
      '@media (max-width: 800px)': {
        marginLeft: 0,
      },
   },
   text:{
    marginTop:30,
    marginBottom:30,
    fontSize: 17,
    lineHeight:1.4,
    color: "#707070",
   },
   infoTitle:{
      fontSize: 20,
      color: "#595959",
      marginTop: 50,
   },
   infoWrapper2:{
      position:"relative",
      marginLeft: 30,
      paddingTop: 30,
      marginBottom: 30,
      '@media (max-width: 800px)': {
        marginLeft: 0,
      },
   },
   textDesignGreen:{
      maxWidth: 750,
      borderRadius: 56,
      background: "linear-gradient(221.82deg, #B4EC51 0%, #39A269 83.52%, #29986C 94.47%, #29986C 94.47%, #23946D 98.85%, #21936D 100%)",
      display: "flex",
      justifyContent: "center",
      marginBottom: 20,
      minHeight: 110
   },
   infoText1:{
      width: "calc(100% - 100px)",
      margin: "0 auto",
      fontSize: 14,
      color: "#FFF",
      fontWeight: "300",
      padding: "25 0",
      letterSpacing: 1.2,
   },
   textDesignWhite:{
      maxWidth: 750,
      borderRadius: 56,
      border: "1px solid #4CAF50",
      background: "#FFF",
      display: "flex",
      justifyContent: "center",
      marginBottom: 20,
   },
   infoText2:{
      width: "calc(100% - 100px)",
      margin: "0 auto",
      fontSize: 24,
      color: "#4CAF50",
      fontWeight: "300",
      padding: "35 0",
      letterSpacing: 1.08,
   },
   flexAlign:{
       display: "flex",
      alignItems: "center"
   },
   infoWrapper3:{
      marginLeft: 20,
      paddingTop: 30,
   },
});

export default AboutPage;
