import Switch from 'react-router-dom/Switch';
import Route from 'react-router-dom/Route';
import autobind from 'core-decorators/lib/autobind';
import React from 'react';

import HomePage from './pages/HomePage';
import RedirectPage from './pages/RedirectPage';
import AboutPage from './pages/AboutPage';
import ContactPage from './pages/ContactPage';
import NewsPage from './pages/NewsPage';
import ProductionPage from './pages/ProductionPage';
import PrivacyPage from './pages/PrivacyPage';


import VerticalSignalingPage from './pages/ProductsPages/VerticalSignalingPage';
import HorizontalSignalingPage from './pages/ProductsPages/HorizontalSignalingPage';
import OtherSignalingPage from './pages/ProductsPages/OtherSignalingPage';
import TrafficEquipmentPage from './pages/ProductsPages/TrafficEquipmentPage';
import OtherEquipmentPage from './pages/ProductsPages/OtherEquipmentPage';
import CatalogsPage from './pages/ProductsPages/CatalogsPage';
import NavodilaPage from './pages/ProductsPages/NavodilaPage';
import GaleryPage from './pages/ProductsPages/GaleryPage';







@autobind
class Routes extends React.Component {

	render(){
		return (
		<main>
			<Switch >
				<Route exact path="/" component={HomePage} />
				<Route exact path="/domov" component={HomePage} />
				<Route exact path="/prometni-znaki" component={HomePage} />
				<Route exact path="/javno3" component={HomePage} />
				<Route exact path="/dopolni" component={HomePage} />
				<Route exact path="/obvezno" component={HomePage} />
				<Route exact path="/obvestila" component={HomePage} />
				<Route exact path="/mojznak" component={RedirectPage} />
				<Route exact path="/znak-po-meri" component={RedirectPage} />
				<Route exact path="/pznaki" component={HomePage} />
				<Route exact path="/certifikati/certifikati" component={HomePage} />

				<Route exact path="/znaki" component={VerticalSignalingPage} />
				<Route path="/znaki/vertikalna-signalizacija" component={VerticalSignalingPage} />
				<Route path="/znaki/prometni-znaki" component={VerticalSignalingPage} />
				<Route path="/znaki/horizontalna-signalizacija" component={HorizontalSignalingPage} />
				<Route path="/znaki/ostala-signalizacija" component={OtherSignalingPage} />
				<Route path="/znaki/prometna-oprema" component={TrafficEquipmentPage} />
				<Route path="/znaki/druga-oprema" component={OtherEquipmentPage} />
				<Route path="/znaki/katalogi" component={CatalogsPage} />
				<Route path="/znaki/navodila" component={NavodilaPage} />
				<Route path="/znaki/galerija-izdelkov" component={GaleryPage} />

				<Route exact path="/izdelki" component={VerticalSignalingPage} />
				<Route path="/izdelki/vertikalna-signalizacija" component={VerticalSignalingPage} />
				<Route path="/izdelki/prometni-znaki" component={VerticalSignalingPage} />
				<Route path="/izdelki/horizontalna-signalizacija" component={HorizontalSignalingPage} />
				<Route path="/izdelki/ostala-signalizacija" component={OtherSignalingPage} />
				<Route path="/izdelki/prometna-oprema" component={TrafficEquipmentPage} />
				<Route path="/izdelki/druga-oprema" component={OtherEquipmentPage} />
				<Route path="/izdelki/katalogi" component={CatalogsPage} />
				<Route path="/izdelki/navodila" component={NavodilaPage} />
				<Route path="/izdelki/galerija-izdelkov" component={GaleryPage} />

				<Route path="/proizvodnja" component={ProductionPage} />
				<Route path="/o-podjetju" component={AboutPage} />
				<Route path="/kontakt" component={ContactPage} />
				<Route path="/novice" component={NewsPage} />
				<Route path="/privacy" component={PrivacyPage} />
				<Route path="/404" component={HomePage} />
				<Route component={HomePage} />
			</Switch>
		</main>
		);
	}

 }

 export default Routes;