import autobind from 'core-decorators/lib/autobind';
import React from 'react';
import { StyleSheet, css } from 'aphrodite';


@autobind
class TeamSelection extends React.Component {
  render() {
    var neki = this.props.img;
   
    return (
        <div className={css(styles.child)}>
          <div>
            <a onClick={()=>window.history.replaceState(null, null, window.location.origin+window.location.pathname+"?mail")} href={this.props.emailHref} className={css(styles.imgWrapper)}>
          
               <div className={css(styles.img2)} style={{backgroundImage:neki}} />

            </a>
            <p className={css(styles.name)}>
              {this.props.name} 
            </p>
            <p className={css(styles.title)}>
              {this.props.title}
            </p>
            <p className={css(styles.education)}>
              {this.props.education}
            </p>
          </div>
          <div>
            <a onClick={()=>window.history.replaceState(null, null, window.location.origin+window.location.pathname+"?tel")} href={this.props.telHref} className={css(styles.tel)}>
              {this.props.tel}
            </a>
            <a onClick={()=>window.history.replaceState(null, null, window.location.origin+window.location.pathname+"?tel")} href={this.props.telHref2} className={css(styles.tel)}>
              {this.props.tel2}
            </a>
            <a onClick={()=>window.history.replaceState(null, null, window.location.origin+window.location.pathname+"?tel")} href={this.props.telHref3} className={css(styles.tel)}>
              {this.props.tel3}
            </a>
            <a onClick={()=>window.history.replaceState(null, null, window.location.origin+window.location.pathname+"?mail")} href={this.props.emailHref} className={css(styles.email)}>
              {this.props.email}
            </a>
          </div>
        </div>
    );
  }
}

const styles = StyleSheet.create({
  child:{
    display:"flex",
    flexDirection:"column",
    justifyContent:"space-between",
    height:345,
    width:180,
    margin:"50px 50px",
    marginLeft:0,
    '@media (max-width: 890px)': {
      width:180,
    },
    '@media (max-width: 800px)': {
      marginRight:20,
    },
    '@media (max-width: 500px)': {
      margin:"30px 0px",
    },
    '@media (max-width: 440px)': {
      width:150,
    },
    '@media (max-width: 361px)': {
      width:130,
    },
  },
  imgWrapper:{
    width:"150px",
    display:"block",
    height:"150px",
    borderRadius:"100%",
    position:"relative",
    overflow:"hidden",
    '@media (max-width: 440px)': {
      width:120,
      height:120,
    },
  },
  img2:{
    width:"150px",
    height:"150px",
    position:"relative",
    cursor:"pointer",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "cover",
    backgroundSize:"150px",
    transition:"0.5s",
    ":hover":{
       backgroundImage:"url(../assets/icons/svg/mail.png)",
    },
    '@media (max-width: 440px)': {
      width:120,
      height:120,
      backgroundSize:"120px",
    },

  },
  name:{
    marginTop:30,
    color:"#595959",
    fontSize:20,
    '@media (max-width: 440px)': {
      fontSize:16,
    },
    '@media (max-width: 361px)': {
      fontSize:15,
    },
  },
  title:{
    color:"#4CAF50",
    fontSize:12,
    marginBottom:5,
    '@media (max-width: 440px)': {
      fontSize:10,
    },
  },
  education:{
    color:"#595959",
    fontSize:12,

    '@media (max-width: 440px)': {
      fontSize:10,
    },
  },
  tel:{
    color:"#838383",
    fontSize:17,
    display:"block",
    textDecoration:"none",
    '@media (max-width: 440px)': {
      fontSize:14,
    },
    '@media (max-width: 361px)': {
      fontSize:13,
    },
  },
  email:{
    display:"block",
    textDecoration:"none",
    color:"#838383",
    fontSize:17,
    '@media (max-width: 440px)': {
      fontSize:14,
    },
    '@media (max-width: 361px)': {
      fontSize:13,
    },
  },


   
});

export default TeamSelection;
