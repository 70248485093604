import autobind from 'core-decorators/lib/autobind';
import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import Helmet from "react-helmet";

import Catalog from '../ProductsPages/productsComponents/Catalog.js';

@autobind
class TrafficEquipmentPage extends React.Component {

  render() {
    return (
      <div className={css(styles.wrapper)}>
        <Helmet>
          <title>Prometna oprema - Signaco</title>
          <meta name="description" content="Podjetje za prodajo prometnih znakov, izdelujemo prometne znake, nosilne drogove, portale, sidra ter ostalo komunalno in urbano opremo, prometna signalizacija"/>
        </Helmet>
        <h1 className={css(styles.title)}>
          prometna oprema
        </h1>
        <Catalog
          title="Cestna oprema"
          text="Nudimo vam celoten spekter prometne opreme priznanih proizvajalcev za vodenje prometa kot so ogledala, stožci, zaporne table, luči utripalke, hitrostne ovire, cestne pregrade, triopan znaki, povozni stebrički,..."
          katalogHref="../../assets/katalogi/V_01_Cestnaoprema.pdf"
          imgSrc="../assets/katalogiImg/V_01_Cestna oprema.png"
        />
        <Catalog
          title="Kovinska oprema"
          text="Po naročilu."
          katalogHref="../../assets/katalogi/V_02_Kovinskaoprema.pdf"
          imgSrc="../assets/katalogiImg/V_02_Kovinska oprema.png"
        />
        <Catalog
          title="Urbana oprema"
          text=""
          katalogHref="../../assets/katalogi/V_03_Urbanaoprema.pdf"
          imgSrc="../assets/katalogiImg/V_03_Urbana oprema.png"
        />
        <Catalog
          title="Varovalne in odbojne ograje"
          text=""
          katalogHref="../../assets/katalogi/V_04_Ograje.pdf"
          imgSrc="../assets/katalogiImg/V_04_Ograje.png"
        />
      </div>
    );
  }
}


const styles = StyleSheet.create({
  wrapper:{
    margin:"40px auto",
    maxWidth:1000,
    width:"calc(100% - 100px)",
    '@media (max-width: 580px)': {
      width: "calc(100% - 60px)",
    },
    '@media (max-width: 340px)': {
      width: "calc(100% - 40px)",
    },
  },
  title:{
    color: "#838383",
    fontSize: 40,
    fontWeight: 300,
    letterSpacing: 1.8,
    lineHeight: "56px",
  },
});

export default TrafficEquipmentPage;
