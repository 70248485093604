import autobind from 'core-decorators/lib/autobind';
import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import Navigation from './Navigation';



@autobind
class NavigationFixed extends React.Component {
  state = {
    open: false,
    opacityLayerTransition: true,
  };

  handleClose(){
    setTimeout(() => this.setState({opacityLayerTransition: true}), 500);
    this.setState({open: false});
  }
  handleOpen(){
    this.setState({opacityLayerTransition: false});
    this.setState({open: true});
  }

  render() {
    const opacityLayerStateDisplay = this.state.opacityLayerTransition ? styles.displayNone : styles.displayBlock;
    const navState = this.state.open ? styles.wrapperOpen : styles.wrapperClose;
    const opacityLayerState = this.state.open ? styles.opacityLayerOpen : styles.opacityLayerClose;
    
    return (
      <div>
        <div className={css(styles.wrapper, navState)}>
          <div className={css(styles.closeNav, navState)}>
            <div onClick={this.handleClose} className={css(styles.closeButtonWrapper)}>
              <img alt="prometni znaki signaco" className={css(styles.closeButton)} src="../assets/icons/menuClose.png"/>
            </div>
          </div>
          <div className={css(styles.navigationWrapper)}>
            <div className={css(styles.navigation)}>
              <Navigation onClose={()=>{setTimeout(() =>{this.handleClose()}, 600)}} />
              
            </div>
          </div>
        </div>
        <div 
          onClick={this.handleClose}
          className={css(opacityLayerStateDisplay, styles.opacityLayer, opacityLayerState)} 
        />
      </div>
    );
  }
}
const styles = StyleSheet.create({
  wrapper:{
    position:"fixed",
    zIndex:10000,
    background:"#fff",
    top:0,
    width:280,
    height:"100%",
    transition: "0.4s",
    '@media (min-width: 1090px)': {
      display:"none",
    },
  },
  wrapperOpen: {
    left:0,
  },
  wrapperClose: {
    left:-280,
  },
  navigationWrapper:{
    overflowY: "auto",
    position:"relative",
    height:"100%",
  },
  navigation:{
    height:"100%",
    minHeight:"100%",
    position:"relative",
   
    
  },
  displayNone:{
    display:"none",
  },
  displayBlock:{
    display:"block",
  },
  opacityLayer:{
    position:"fixed",
    top:0,
    bottom:0,
    left:0,
    right:0,
    zIndex:100,
    transition:"0.4s",
    '@media (min-width: 1090px)': {
      display:"none",
    },
  },
  opacityLayerOpen:{
    background:"rgba(0,0,0,0.4)",
  },
  opacityLayerClose:{
    background:"rgba(0,0,0,0)",
  },
  closeNav:{
    display:"flex",
    justifyContent:"flex-end",
    position:"fixed",
    zIndex:100000,
    background: "linear-gradient(rgba(255,255,255,1) 10%, rgba(255,255,255,0.9) 30%, rgba(255,255,255,0) 100%)",
    top:0,
    width:260,
    marginLeft:10,
    transition: "0.4s",
  },
  closeButtonWrapper:{
    padding:20,
    cursor:"pointer",
    background:"transparent",
  },
  closeButton:{
    width:40,

  },
  fbIgIco: {
    display: 'flex',
    alignItems:"center",
    position:"absolute",
    bottom:0,
    left:10,
  },
  image1: {
    marginRight:26,
    width:8,
  },
  image2: {
    width:20,
  },
});

export default NavigationFixed;
