import autobind from 'core-decorators/lib/autobind';
import React from 'react';
import { StyleSheet, css } from 'aphrodite';


@autobind
class NewPopup extends React.Component {
  props: Props;
  state = {
    display:false,
    delay:false,
  };

  
  hide(){
    this.setState({display:false});
    setTimeout(() => this.setState({ delay: false}), 600);
    var now = new Date();
    var time = now.getTime();
    time += 40000000000;
    now.setTime(time);
    document.cookie = 
    'new1=yes' + 
    '; expires=' + now.toUTCString() + 
    '; path=/';
  }

  componentDidMount(){
    if(document.cookie.replace(/(?:(?:^|.*;\s*)new1\s*=\s*([^;]*).*$)|^.*$/, "$1") === "yes"){
      this.setState({ delay: false});
    } else {
      setTimeout(() => this.setState({ delay: true, display:true}), 6000);
    }
  }


  render() {
  const displayState = this.state.display ? styles.displayON : styles.displayOFF;
    
    return (
      <div className={css(styles.wrapper, displayState)}>
        <div className={css(styles.popup, displayState)}>
          <img alt="close" onClick={this.hide} className={css(styles.close)} src="../assets/icons/close.png" />
          <h2 className={css(styles.title)}>novo</h2>
          <p className={css(styles.text)}>Preizkusite testno verzijo Signaco-vega novega spletnega naročevalnika, ki vam omogoča oddajo naročila kar prek spleta.</p>
          <a onClick={this.hide} className={css(styles.contact5)} href="https://narocevalnik.signaco.si/">NAROČEVALNIK
            <img className={css(styles.contact5Img)} alt="prometni znaki naprej" src="../assets/icons/next.png" />
          </a>
        </div>
      </div>
    );
  }
}


const styles = StyleSheet.create({
  title:{
    color: "#838383",
    fontSize: 40,
    fontWeight: 300,
    letterSpacing: 1.8,
    lineHeight: "56px",
  },
  text:{
    marginTop:30,
    marginBottom:20,
    maxWidth:440,
    fontSize: 14,
    lineHeight:1.4,
    color: "#707070",
  },
  wrapper:{
    margin:"0 auto",
    width:"100%",
    position:"fixed",
    boxShadow: "0 0 20px 0 rgba(89,89,89,0.2)",
    zIndex:"1000000",
    bottom:0,
    color:"#000",
    display:"flex",
    textAlign:"center",
    justifyContent:"center",
    alignItems:"center",
    background:"rgba(0,0,0,0.5)",
    left:0,
    top:0,
    right:0,
  },
  popup:{
    position:"relative",
    textAlign:"center",
    background:"#fff",
    padding:60,
  },
  displayON:{
    visibility:"visible",
  },
  displayOFF:{
    visibility:"hidden",
  },
  button:{
    backgroundColor: "",
    borderBottom: "#fff 2px solid",
    padding: "2 2",
    color: "#fff",
    textDecoration:"none",
    marginLeft:30,
    position: "relative",
    transition: "all .2s",
    cursor:"pointer",
    '@media (max-width: 780px)': {
      marginLeft:0,
      marginTop:7,
    },
  },
  close:{
    position:"absolute",
    width:14,
    top:24,
    right:24,
    cursor:"pointer",
    zIndex:1000,
    '@media (max-width: 780px)': {
      bottom:29,
      right:20,
    },
    '@media (max-width: 540px)': { 
      bottom:39,
    },
  },


 contact5:{
    textAlign:"center",
    margin:"0 auto",
    marginTop:40,
    display:"flex",
    textDecoration:"none",
    borderRadius:20,
    paddingLeft:10,
    alignItems:"center",
    background:"#4CAF50",
    color: "#fff",
    fontSize: 16,
    transition:"0.3s",
    fontWeight: 500,
    height:40,
    width:190,
    justifyContent:"center",
    letterSpacing: 0.5,
    lineHeight: "32px",
    ":hover":{
      transform:"scale(1.05)",
    }
  },
  contact5Img:{
    width:27,
    paddingLeft:40,
  },
  
});

export default NewPopup;
