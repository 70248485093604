import autobind from 'core-decorators/lib/autobind';
import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import Helmet from "react-helmet";
import Gallery from 'react-photo-gallery';
import Lightbox from 'react-images';


const photos = [];
for (let i = 1; i < 33; i++ ) {
  photos.push({src: "../assets/galery/"+i+".jpg", width: 4, height: 3 });
}
for (let i = 33; i < 51; i++ ) {
  photos.push({src: "../assets/galery/"+i+".png", width: 4, height: 3});
}


@autobind
class GaleryPage extends React.Component {
  props: Props;
  state = {
    items:[...photos],
    currentImage: 0,
    items2:[],
    max: false,
    show:0,
    intervalId: undefined,
    subTitle:[
    "1","2","3","4",
    "5","6","7","8",
    "9","10","11","12",
    "13","14","15","16",
    "17","18","19","20",
    "21","22","23","24",
    "25","26","27","28",
    "29","30","31","32",
    "33","34","35","36",
    "37","38","39","41",
    "42","43","44",
    ],
  };
  

  componentWillUnmount(){
 
  }

  openLightbox(event, obj) {
    this.setState({
      currentImage: obj.index,
      lightboxIsOpen: true,
    });
  }
  closeLightbox() {
    this.setState({
      currentImage: 0,
      lightboxIsOpen: false,
    });
  }
  gotoPrevious() {
    this.setState({
      currentImage: this.state.currentImage - 1,
    });
  }
  gotoNext() {
    this.setState({
      currentImage: this.state.currentImage + 1,
    });
  }

  render() {

    // const transition = this.state.transition ? styles.transition : null;

    return (
      <div onMouseEnter={this.start} className={css(styles.wrapper)}>
        <Helmet>
          <title>Galerija - Signaco</title>
          <meta name="description" content="Podjetje za prodajo prometnih znakov, izdelujemo prometne znake, nosilne drogove, portale, sidra ter ostalo komunalno in urbano opremo, prometna signalizacija"/>
        </Helmet>
        <div className={css(styles.innerWrapper)}>
          <h1 className={css(styles.title)}>
            galerija
          </h1>

         <Gallery photos={photos} onClick={this.openLightbox} />
            <Lightbox images={photos}
              onClose={this.closeLightbox}
              onClickPrev={this.gotoPrevious}
              onClickNext={this.gotoNext}
              currentImage={this.state.currentImage}
              isOpen={this.state.lightboxIsOpen}
            />
          </div>
      </div>
    );
  }
}

const transition = {
    '0%': { opacity: 1, },
    '40%': { opacity: 0, },
    '60%': { opacity: 0, },
    '100%': { opacity: 1, },
};


const styles = StyleSheet.create({
	wrapper:{
    margin:"40px auto",
    maxWidth:1000,
    width:"calc(100% - 100px)",
    '@media (max-width: 580px)': {
      width: "calc(100% - 60px)",
    },
    '@media (max-width: 340px)': {
      width: "calc(100% - 40px)",
    },
  },
  innerWrapper:{
    maxWidth: 1000,
    marginTop:50,
    marginBottom:50,
  },
  title:{
    color: "#838383",
    fontSize: 40,
    fontWeight: 300,
    letterSpacing: 1.8,
    lineHeight: "56px",
    marginBottom:30,
  },
  flex:{
    marginLeft:30,
    marginTop:40,
    '@media (max-width: 800px)': {
      marginLeft:0,
    },
  },
  imgWrapper:{

    marginTop:10,
    border:"none",
  },
  img:{
    maxWidth:730,
    width:"100%",
    height:500,
    borderRadius:20,
    border:"none",
    cursor:"pointer",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "50%",
    backgroundSize:"cover",
    transition:"0.5s",
    '@media (max-width: 560px)': {
      height:400,
    },
    '@media (max-width: 500px)': {
       height:280,
    },
    '@media (max-width: 372px)': {
       height:220,
    },
  },
  smallImg:{
    borderRadius:20,
    width:"calc(33% - 16px)",
    height:150,
    margin:8,
    cursor:"pointer",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "50%",
    backgroundSize:"cover",
    transition:"0.2s",
    ":hover":{
      opacity:0.7,
    },
    '@media (max-width: 560px)': {
      width:"calc(50% - 16px)",
    },
    '@media (max-width: 400px)': {
       height:130,
    },
    '@media (max-width: 372px)': {
       height:110,
    },
  },
  subTitle:{
    fontWeight:300,
    fontSize:15,
    marginTop:10,
  },
  smallImgWrapper:{
    maxWidth:754,
    width:"calc(100% + 20px)",
    marginLeft:-8,
    display:"flex",
    flexWrap:"wrap",
    jusifyContnet:"space-between",
    marginTop:30,
    '@media (max-width: 560px)': {
      width:"calc(100% + 16px)",
    },
  },
  button: {
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    fontWeight:300,
    letterSpacing:0.4,
    cursor:"pointer",
    fontSize:15,
    lineHeight:1.7,
    height:40,
    width:190,
    color:"#fff",
    borderRadius:23,
    background:"#4CAF50",
    marginTop:30,
   '@media (max-width: 860px)': {
       width:160,
    },
    '@media (max-width: 760px)': {
      width:130,
    },
    '@media (max-width: 660px)': {
      marginRight:20,
    },
  },

  transition: {
    animationName: transition,
    animationDuration: "0.5s",
    animationTimingFunction: "ease-in-out",
  }
});

export default GaleryPage;
