import autobind from 'core-decorators/lib/autobind';
import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import Helmet from "react-helmet";

@autobind
class RedirectPage extends React.Component {

  render() {
    setTimeout(() => window.location.replace("https://mojznak.signaco.si"), 500)
    return (
      <div className={css(styles.wrapper)}>
        <Helmet>
          <title>Znak po meri - Izdelajte svoj znak po meri v samo treh korakih!</title>
          <meta name="description" content="Vsi produkti so izdelani po najvišjih standardih kakovosti in so tako obstojni in odporni na vse vremenske vplive, hkrati pa izgledajo odlično."/>
        </Helmet>
        <div className={css(styles.innerWrapper)}>
          <h1 className={css(styles.title)}>
            Znak po meri
          </h1>
          <p className={css(styles.text)}>Vsi produkti so izdelani po najvišjih standardih kakovosti in so tako obstojni in odporni na vse vremenske vplive, hkrati pa izgledajo odlično.</p>
          <p className={css(styles.text)}>V našem urejevalniku lahko svojo idejo spremenite v čudovito darilo in z njim presenetite svoje bližnje.</p>
        </div>
      </div>
    );
  }
}


const styles = StyleSheet.create({
	wrapper:{
    margin:"40px auto",
    maxWidth:1000,
    width:"calc(100% - 100px)",
    '@media (max-width: 580px)': {
      width: "calc(100% - 60px)",
    },
    '@media (max-width: 340px)': {
      width: "calc(100% - 40px)",
    },
  },
  innerWrapper:{
    maxWidth:700,
  },
  title:{
    color: "#838383",
    fontSize: 40,
    fontWeight: 300,
    letterSpacing: 1.8,
    lineHeight: "56px",
  },
  title2:{
    color: "#838383",
    fontSize: 40,
    fontWeight: 300,
    letterSpacing: 1.8,
    lineHeight: "56px",
    marginTop:30,
  },
  text:{
    marginTop:20,
    color: "#595959",
    fontSize: 12,
    lineHeight: "18px",
    marginLeft:30,
    '@media (max-width: 800px)': {
      marginLeft: 0,
    },
  },
  img:{
    marginLeft:30,
    background:"#F1F1F1",
    height:300,
    marginTop:50,
    width:"calc(100% - 30px)",
    '@media (max-width: 800px)': {
      marginLeft: 0,
    },
  },
});

export default RedirectPage;
