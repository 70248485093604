import autobind from 'core-decorators/lib/autobind';
import React from 'react';
import Link from 'react-router-dom/Link';
import { StyleSheet, css } from 'aphrodite';



@autobind
class InlineListServices extends React.Component {
  render() {
    return (
      <div className={css(styles.wrapper)}>
        <div className={css(styles.flex)}>

          <div className={css(styles.linkWrapper)}>
            <Link className={css(styles.link)} onClick={()=>window.scrollTo(0,0)} to="/">
              DOMOV
            </Link>
            <Link className={css(styles.link)} onClick={()=>window.scrollTo(0,0)} to="/znaki">
              IZDELKI
            </Link>
            {/*<Link className={css(styles.link)} onClick={()=>window.scrollTo(0,0)} to="/proizvodnja">
              PROIZVODNJA
            </Link>*/}
            <Link className={css(styles.link)} onClick={()=>window.scrollTo(0,0)} to="/o-podjetju">
              O PODJETJU
            </Link>
            <Link className={css(styles.link)} onClick={()=>window.scrollTo(0,0)} to="/kontakt">
              KONTAKT
            </Link>
            <Link className={css(styles.link)} onClick={()=>window.scrollTo(0,0)} to="/novice">
              NOVICE
            </Link>
          </div>

          <div className={css(styles.kontaktWrapper)}>
            <a className={css(styles.tel)} onClick={()=>window.history.replaceState(null, null, window.location.origin+window.location.pathname+"?tel")}  href="tel:+386 (0) 1 723 09 73">
              +386 (0) 1 723 09 73
            </a>
            <a className={css(styles.mail)} href="mailto:info@signaco.si" onClick={()=>window.history.replaceState(null, null, window.location.origin+window.location.pathname+"?mail")}>
              info@signaco.si
            </a>
          </div>

        </div>
        <div className={css(styles.flex2)}>
          <Link className={css(styles.link)} onClick={()=>window.scrollTo(0,0)} to="/privacy">
              Pogoji uporabe
          </Link>
        </div>
      </div>
    );
  }
}
const styles = StyleSheet.create({
  wrapper: {
    position:"relative",
    background: "#D8D8D8",
    paddingBottom:40,
    paddingTop:40,
    '@media (max-width: 860px)': {
      paddingBottom:30,
      paddingTop:30,
    },
  },
  flex:{
    width:"calc(100% - 100px)",
    display:"flex",
    maxWidth:1000,
    alignItems:"center",
    justifyContent: 'space-between',
    margin:"0px auto",
    '@media (max-width: 860px)': {
      flexDirection:"column",
      width:"calc(100% - 50px)",
    },
    
  },
  flex2:{
    width:"calc(100% - 100px)",
    display:"flex",
    maxWidth:1000,

    alignItems:"center",
    justifyContent: 'space-between',
    margin:"0px auto",
    '@media (max-width: 860px)': {
      flexDirection:"column",
      width:"calc(100% - 50px)",
      marginTop:15,
    },
    
  },
  linkWrapper:{
    maxWidth:600,
    display:"flex",
    alignItems:"center",
    justifyContent: 'space-between',
    '@media (max-width: 860px)': {
      paddingBottom:20,
    },
    '@media (max-width: 373px)': {
      flexDirection:"column",
    },
  },
  link:{
    textDecoration:"none",
    color: "#595959",
    padding: 4,
    whiteSpace: "nowrap",
    fontSize:11,
    margin:"0 10px",
    fontWeight:500,
    webkitTransition:"0.3s",
    transition:"0.3s",
    display:"block",
    ":hover":{
      color:"#4CAF50",
    },
    '@media (max-width: 460px)': {
      margin:"0 5px",
    },
    '@media (max-width: 373px)': {
      margin:"3px 5px",
    },
  },
  kontaktWrapper:{
    display:"flex",
    alignItems:"center",
    justifyContent:"space-between",
    '@media (max-width: 383px)': {
      flexDirection:"column",
    },
  },
  tel:{
    textDecoration:"none",
    color: "#595959",
    fontSize:15,
    fontWeight:300,
    marginRight:15,
    '@media (max-width: 383px)': {
      marginBottom:12,
    },
    ":hover":{
      color:"#4CAF50",
    },
  },
  mail:{
    height: 30,
    width: 160,
    fontWeight:300,
    fontSize:15,
    transition:"0.2s",
    textDecoration:"none",
    color: "#595959",
    display:"flex",
    alignItems:"center",
    justifyContent:"center",
    borderRadius: 19,
    backgroundColor: "#FFFFFF",
    ":hover":{
      color:"#4CAF50",
    },
  },

});

export default InlineListServices;