import autobind from 'core-decorators/lib/autobind';
import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import Helmet from "react-helmet";

import Catalog from '../ProductsPages/productsComponents/Catalog.js';

@autobind
class OtherSignalingPage extends React.Component {

  render() {
    return (
      <div className={css(styles.wrapper)}>
        <Helmet>
          <title>Ostala signalizacija - Signaco</title>
          <meta name="description" content="Podjetje za prodajo prometnih znakov, izdelujemo prometne znake, nosilne drogove, portale, sidra ter ostalo komunalno in urbano opremo, prometna signalizacija"/>
        </Helmet>
        <h1 className={css(styles.title)}>
          ostala signalizacija
        </h1>
        <Catalog
          title="Gradbiščne table"
          text="Izdelujemo različne tipe gradbiščnih tabel. Delimo jih na opozorilne table, informacijske table, razlagalne table EU ter deloviščne table."
          katalogHref="../../assets/katalogi/III_01_Gradbiscnetable.pdf"
          imgSrc="../assets/katalogiImg/III_01_Gradbiscne table.png"

        />
        <Catalog
          title="Varnostni znaki"
          text="Z varnostnimi znaki označujemo prepovedi, obveznosti, opozorila in lastnosti dejanj in stanj, ki obveščajo o tveganju za varnost in zdravje."
          katalogHref="../../assets/katalogi/III_02_Varnostniznaki.pdf"
          imgSrc="../assets/katalogiImg/III_02_Varnostni znaki.png"
        />
        <Catalog
          title="Reklamne table"
          text="Izdelujemo različne tipe reklamnih tabel, kažipotov in napisov po vaši ali naši predlogi."
          katalogHref="../../assets/katalogi/III_03_Reklamnetable.pdf"
          imgSrc="../assets/katalogiImg/III_03_Reklamne table.png"
        />
        <Catalog
          title="Druge obvestilne table"
          text="Opozorilno - obvestilne table in/ali nalepke so namenjene individualnim potrebam po obveščanju in informiranju uporabnikov javnih ali zasebnih površin. Izdelujemo tudi table s poljubnim besedilom in motivom po vaši predlogi."
          katalogHref="../../assets/katalogi/III_04_Drugeobvestilnetable.pdf"
          imgSrc="../assets/katalogiImg/III_04_Druge obvestilne table.png"
        />
        <Catalog
          title="Znaki za smučišča"
          text="Vrsto in obliko znakov za smučišča določa Pravilnik o znakih in označbah na smučiščih (Ur. l. RS št. 116/2008) in slovenski standard SIST EN ISO 7010:2012 in SIST EN ISO 12944-5."
          katalogHref="../../assets/katalogi/IV_01_Smucisca.pdf"
          imgSrc="../assets/katalogiImg/IV_01_Smucisca.png"
        />
        <Catalog
          title="Znaki za planinske in pohodniške poti"
          text="Vrsto in obliko znakov za planinske poti določa Pravilnik o označevanju in opremljanju planinskih poti (Ur. l. RS št. 80/2008). Vrsto in obliko znakov za pohodniške poti določajo Priporočila za postavitev signalizacije za pohodne poti in poti nordijske hoje."
          katalogHref="../../assets/katalogi/IV_02_Planinskeinpohodniskepoti.pdf"
          imgSrc="../assets/katalogiImg/IV_02_Planinske in pohodniske poti.png"
        />
        <Catalog
          title="Znaki za označevanje naravnih vrednot"
          text="Vrsto in obliko znakov za označevanje naravnih vrednot določa Pravilnik o označevanju zavarovanih območij naravnih vrednot (Ur. l. RS št. 117/2002 in 53/2005)."
          katalogHref="../../assets/katalogi/IV_03_Naravnevrednote.pdf"
          imgSrc="../assets/katalogiImg/IV_03_Naravne vrednote.png"
        />
        <Catalog
          title="Znaki za označevanje nepremičnih kulturnih spomenikov"
          text="Vrsto in obliko znakov za označevanje nepremičnih kulturnih spomenikov določa Pravilnik o označevanju kulturnih spomenikov (Ur. l. RS št. 16/2008 in 123/2008)."
          katalogHref="../../assets/katalogi/IV_04_Kulturnispomeniki.pdf"
          imgSrc="../assets/katalogiImg/IV_04_Kulturni spomeniki.png"
        />
        <Catalog
          title="Znaki po naročilu"
          text="Znaki po naročilu so namenjeni posebnim priložnostim kot darilo ob praznovanju rojstnega dne, obletnice ali kakšne druge priložnosti. Pošljite nam vaš grafični osnutek in fotografijo ali risbo, lahko pa vam ga pripravimo mi po vaših željah. (DODAMO PODSTRAN)"
          katalogHref="../../assets/katalogi/VII_Znakiponarocilu.pdf"
          imgSrc="../assets/katalogiImg/VII_Znakiponarocilu.png"
        />
      </div>
    );
  }
}


const styles = StyleSheet.create({
	wrapper:{
    margin:"40px auto",
    maxWidth:1000,
    width:"calc(100% - 100px)",
    '@media (max-width: 580px)': {
      width: "calc(100% - 60px)",
    },
    '@media (max-width: 340px)': {
      width: "calc(100% - 40px)",
    },
  },
  title:{
    color: "#838383",
    fontSize: 40,
    fontWeight: 300,
    letterSpacing: 1.8,
    lineHeight: "56px",
  },
});

export default OtherSignalingPage;
