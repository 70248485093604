import autobind from 'core-decorators/lib/autobind';
import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import Helmet from "react-helmet";

import Catalog from '../ProductsPages/productsComponents/Catalog.js';

@autobind
class HorizontalSignalingPage extends React.Component {

  render() {
    return (
      <div className={css(styles.wrapper)}>
        <Helmet>
          <title>Horizontalna signalizacija - Signaco</title>
          <meta name="description" content="Podjetje za prodajo prometnih znakov, izdelujemo prometne znake, nosilne drogove, portale, sidra ter ostalo komunalno in urbano opremo, prometna signalizacija"/>
        </Helmet>
        <div className={css(styles.title)}>
          horizontalna signalizacija
        </div>
        <Catalog
          title="Tankoslojne in debeloslojne označbe"
          text="Označbe na prometnih površinah so horizontalna prometna signalizacija, s katero na prometnih površinah označujemo nevarnosti, prepovedi, omejitve in druge obveznosti ter obvestila za udeležence cestnega prometa."
          katalogHref="../../assets/katalogi/VI_Horizontalnasign.pdf"
          imgSrc="../assets/katalogiImg/VI_Horizontalnasign.png"
          dodatniText="<div style='margin-top:15px;font-size:18px;'>več na: <a style='color:#4CAF50;font-size:18px;text-decoration:none;' href='http://www.signa.si' >www.signa.si</a></div>"
        />
        <Catalog
          title="Spreji za označevanje"
          text="Spreji s kratkotrajnim obstankom (do 12 mesecev) so idealni za kratkotrajno označevanje površin v prometu, industriji, gradbeništvu, gozdarstvu,…"
          katalogHref="../../assets/katalogi/IX_Spreji.pdf"
          imgSrc="../assets/katalogiImg/IX_Spreji.png"
        />
        <Catalog
          title="Druga talna signalizacija"
          text="Talne označbe za javna in zasebna parkirišča, garažne hiše, otroška igrišča, športna igrišča, proti drsne prevleke, taktilne označbe za slepe,…"
          katalogHref="../../assets/katalogi/X_Drugetalneoznacbe.pdf"
          imgSrc="../assets/katalogiImg/X_Drugetalneoznacbe.png"
        />
      </div>
    );
  }
}


const styles = StyleSheet.create({
  wrapper:{
    margin:"40px auto",
    maxWidth:1000,
    width:"calc(100% - 100px)",
    '@media (max-width: 580px)': {
      width: "calc(100% - 60px)",
    },
    '@media (max-width: 340px)': {
      width: "calc(100% - 40px)",
    },
  },
  title:{
    color: "#838383",
    fontSize: 40,
    fontWeight: 300,
    letterSpacing: 1.8,
    lineHeight: "56px",
  },
});


export default HorizontalSignalingPage;
