import autobind from 'core-decorators/lib/autobind';
import React from 'react';
import { StyleSheet, css } from 'aphrodite';

@autobind
class CatalogMini extends React.Component {

  render() {
    return (         
          <div className={css(styles.wrapper)}>
            <a className={css(styles.imgWrapper)} href={this.props.katalogHref} target="_blank" rel="noopener noreferrer">
              <img alt={"prometni znaki "+this.props.imgSrc} src={this.props.imgSrc} className={css(styles.img)} />
            </a>

            <h2 className={css(styles.title)}>
              {this.props.title}
            </h2>
            
            <a href={this.props.katalogHref} target="_blank" rel="noopener noreferrer" className={css(styles.buttonWrapper)}>
              <img alt="prometni znaki buttonCatalog" src="../assets/icons/svg/buttonCatalog.svg" className={css(styles.buttonImg)} />
              <div className={css(styles.buttonText)}>
                Katalog
              </div>
            </a>
              
        </div>
    );
  }
}


const styles = StyleSheet.create({
	wrapper:{
		width:150,
    textAlign:"center",
    '@media (max-width: 540px)': {
      width:130,
    },
	},
  imgWrapper:{
    textDecoration:"none",
    display:"block",
    transition:"0.2s",
    cursor:"pointer",
    position:"relative",

    ":hover":{
      opacity:0.7,
    },
  },
  img:{
    width:130,
    borderRadius:19,
    '@media (max-width: 540px)': {
      width:100,
    },
  },
  title:{
    height:45,
    color: "#737373",
    fontSize: 12,
    marginTop:12,
    marginBottom:10,
    fontWeight: 400,
    
    '@media (max-width: 470px)': {
      fontSize: 11,
    },
  },
  
  buttonWrapper:{
    textDecoration:"none",
    display:"flex",
    width:90,
    margin:"0 auto",
    alignItems:"center",
    justifyContent: 'center',
    cursor:"pointer",
    '@media (max-width: 413px)': {
      marginTop:20,
    },
  },
  buttonImg:{
    width:30,
    marginRight:10,
  },
  buttonText:{
    textDecoration:"none",
    color: "#595959",
    fontSize: 12,
  },

});

export default CatalogMini;
