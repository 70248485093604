import autobind from 'core-decorators/lib/autobind';
import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import axios from 'axios';


@autobind
class ContactForm extends React.Component {
  props: Props;
  state = {
    name:"",
    email:"",
    tel:"",
    message:"",
    nacin:"",
    location:"contact",
    startTransition: false,
    nextScreen: false,
    inputOk1: true,
    inputOk2: true,
    inputOk3: true,
  };

  handleClick2(){
    this.setState({startTransition: !this.state.startTransition});
    if(this.props.onClick()){
      this.props.onClick();
    }
  }
  handleRegister1(){
      if(this.state.name === ""){
        this.setState({inputOk1:false});
      } else {
        this.setState({inputOk1:true});
      }
      this.setState({inputOk3:true});
      if(this.state.tel === ""){
        this.setState({inputOk2:false});
      } else {
        this.setState({inputOk2:true});
      }
      if(this.state.name !== "" && this.state.tel !== ""){
        // window.gtag_report_conversion();
        this.props.onClick();
        this.setState({nacin: "Poklicali vas bomo v najkrajšem možnem času. V kolikor našega odgovora ne prejmete v roku treh delovnih dni nam to prosim sporočite na elektronski naslov", startTransition: true});
        setTimeout(() => {
          axios.post('https://email.mtoffice.net/mail3', this.state).then(response => {
              console.log(response);
            })
            .catch(error => {
              console.log(error);
            });
          setTimeout(() => this.setState({ nextScreen: true}), 400);
        }, 200);
      }
  }
  handleRegister2(){
      if(this.state.name === ""){
        this.setState({inputOk1:false});
      } else {
        this.setState({inputOk1:true});
      }

      this.setState({inputOk2:true});

      if(this.state.email === ""){
        this.setState({inputOk3:false});
      } else {
        this.setState({inputOk3:true});
      }
      if(this.state.name !== "" && this.state.email !== ""){
        // window.gtag_report_conversion();
        this.props.onClick();
        this.setState({nacin: "Odpisali vam bomo v najkrajšem možnem času. V kolikor našega odgovora ne prejmete v roku treh delovnih dni nam to prosim sporočite na elektronski naslov", startTransition: true});
        setTimeout(() => {
          axios.post('https://email.mtoffice.net/mail3', this.state).then(response => {
              console.log(response);
            })
            .catch(error => {
              console.log(error);
            });
          setTimeout(() => this.setState({ nextScreen: true}), 400);
        }, 200);
      }
  }

  handleClose(){
    this.props.onClick();
    this.setState({ startTransition: false});
    this.setState({ nextScreen: false});
  }

  render() {
    const inputOkState1 = this.state.inputOk1 ? styles.boxInputOk : styles.boxInputError;
    const inputOkState2 = this.state.inputOk2 ? styles.boxInputOk : styles.boxInputError;
    const inputOkState3 = this.state.inputOk3 ? styles.boxInputOk : styles.boxInputError;
    const firstScreenState = this.state.startTransition ? styles.wrapperOut : styles.wrapperIn;

    
    return (
    <div>
      {!this.state.nextScreen ? 
        <div className={css(firstScreenState)}>
          <div className={css(styles.flex)}>
            <input
              type="name"
              className={css(styles.boxInput, styles.boxInput1, inputOkState1)}
              placeholder="Ime in priimek"
              onChange={e => this.setState({ name: e.nativeEvent.target.value })}
            />
            <input
              type="tel"
              className={css(styles.boxInput, inputOkState2)}
              placeholder="Telefon"
              onChange={e => this.setState({ tel: e.nativeEvent.target.value })}
            />
            <input
              type="email"
              className={css(styles.boxInput, inputOkState3)}
              placeholder="Email"
              onChange={e => this.setState({ email: e.nativeEvent.target.value })}
            />
            <textarea
              rows="3"
              type="text"
              className={css(styles.boxInput)}
              placeholder="Sporočilo"
              onChange={e => this.setState({ message: e.nativeEvent.target.value })}
            />
            <div className={css(styles.text)}>Kako želite da vas kontaktiramo?</div>
            <div className={css(styles.buttonWrapper)}>
              <div onClick={this.handleRegister1} className={css(styles.button)}>Telefon</div>
              <div onClick={this.handleRegister2} className={css(styles.button)}>Email</div>
            </div>
            {!this.state.inputOk1 || !this.state.inputOk2 || !this.state.inputOk3 ?
              <div className={css(styles.redText)}>Prosim izpolnite polja označena z rdečo barvo</div>
            :
              null
            }
          </div>
        </div>
        :
        <div style={{"opacity":0}} className={css(styles.nextScreenSecond)}>
            <div className={css(styles.textCenter)}>{this.state.name}, hvala za oddano povpraševanje.</div>
            <div className={css(styles.textCenterSmall)}>
              Kontaktirali vas bomo v najkrajšem možnem času.
            </div>
            <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
            <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none"/>
            <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
          </svg>

          <div onClick={this.handleClose} className={css(styles.button1)} to="/contact">Nazaj na kontakt
          </div>

        </div>
        }
      </div>
    );
  }
}


const styles = StyleSheet.create({
  wrapperIn: {
    position:"relative",
    opacity:1,
    width:"100%",
    transition:"0.5s",
  },
  wrapperOut: {
    position:"relative",
    opacity:0,
    width:"100%",
    margin:"0 auto",
    transition:"0.5s",
  },
  flex:{
    maxWidth:450,
    '@media (max-width: 860px)': {
      maxWidth:350,
    },
    '@media (max-width: 760px)': {
      maxWidth:280,
    },
    '@media (max-width: 660px)': {
      marginTop:40,
      maxWidth:400,
    },
  },
  link:{
    textDecoration:"none",
    color:"black",
    padding: 4,
    fontSize:17,
    fontWeight:300,
    webkitTransition:"0.3s  cubic-bezier(0,0,0,5)",
    transition:"0.3s  cubic-bezier(0,0,0,5)",
    display:"block",
    ":hover":{
      color:"#333333",
    }
  },
  boxInput1: {
    marginTop:0,
  },
  boxInput: {
    transition:"0.4s",
    width:"calc(100% - 20px)",
    '::-webkit-input-placeholder': {
        color: '#707070',
        letterSpacing:0.4,
        fontWeight:300,
        fontSize:16,
    },
    '::-moz-placeholder': {
      color: '#707070',
      letterSpacing:0.4,
      fontWeight:300,
      fontSize:16,
    },
    ':-ms-input-placeholder': {
      color: '#707070',
      letterSpacing:0.4,
      fontWeight:300,
      fontSize:16,
    },
    marginTop:10,
    outline:"none",
    textAlign:"left",
    fontSize:19,
    paddingTop:5,
    paddingBottom:5,
    paddingLeft:20,
    background: "#fff",
    border:"2px #4CAF50 solid",
    minHeight:30,
    borderRadius:20,
    maxWidth:550,
    marginBottom:15,
    '@media (max-width: 860px)': {
      marginBottom:10,
    },
    '@media (max-width: 760px)': {
      marginBottom:4,
      fontSize:17,
    },
  },
  boxInputOk:{
    border:"2px #4CAF50 solid",
  },
  boxInputError:{
    border:"2px #cc0000 solid",
  },
  text: {
    color:"#595959",
    fontSize:25,
    fontWeight:400,
    margin:"30px auto",
    marginTop:20,
    letterSpacing:0.8,
    '@media (max-width: 860px)': {
       fontSize:22,
    },
    '@media (max-width: 760px)': {
      fontSize:18,
    },
  },
  buttonWrapper: {
    display:"flex",
    justifyContent:"space-between",
    '@media (max-width: 660px)': {
      justifyContent:"flex-start",
    },
  },
  button: {
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    fontWeight:300,
    letterSpacing:0.4,
    cursor:"pointer",
    fontSize:18,
    lineHeight:1.7,
    height:40,
    width:190,
    color:"#fff",
    borderRadius:23,
    background:"#4CAF50",
   '@media (max-width: 860px)': {
       width:160,
    },
    '@media (max-width: 760px)': {
      width:130,
    },
    '@media (max-width: 660px)': {
      marginRight:20,
    },
  },

  nextScreenSecond: {
    transition:"0.6s",
    width:"100%",
    margin:"0 auto",
    opacity:1,
  },

  textCenter: {
    textAlign:"center",
    margin:"20px auto",
    marginTop:60,
    fontWeight:500,
    color:"#707070",
    fontSize:25,
  },
  textCenterSmall: {
    textAlign:"center",
    margin:"20px auto",
    marginBottom:25,
    color:"#4CAF50",
    fontSize:20,
  },

  button1:{
    margin:"0 auto",
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    fontWeight:300,
    letterSpacing:0.4,
    cursor:"pointer",
    fontSize:16,
    lineHeight:1.7,
    height:35,
    width:170,
    color:"#fff",
    borderRadius:23,
    background:"#4CAF50",
    // '@media (max-width: 1040px)': {
    //   fontSize:14,
    // },
  },
  progressBar1:{
    width:90,
    height:2,
    transition:"0.2s ease-out",
    background:"#000",
  },
  redText:{
    color:"#cc0000",
    fontSize:14,
    marginTop:3,
  },

});

export default ContactForm;
