import autobind from 'core-decorators/lib/autobind';
import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import axios from "axios";


@autobind
class SignacoNewsLetter extends React.Component {
  props: Props;
  state = {
    display:false,
    delay:false,
    email: "",
    requred: false
  };

  
  hide(){
    this.setState({display:false});
    setTimeout(() => this.setState({ delay: false}), 600);
    var now = new Date();
    var time = now.getTime();
    time += 40000000000;
    now.setTime(time);
    document.cookie = 
    'new2=yes' + 
    '; expires=' + now.toUTCString() + 
    '; path=/';
  }

  componentWillMount(){
    if(document.cookie.replace(/(?:(?:^|.*;\s*)new1\s*=\s*([^;]*).*$)|^.*$/, "$1") === "yes"){
      if(document.cookie.replace(/(?:(?:^|.*;\s*)new2\s*=\s*([^;]*).*$)|^.*$/, "$1") === "yes"){
        this.setState({ delay: false});
      } else {
        setTimeout(() => this.setState({ delay: true, display:true}), 2000);
      }
    }
  }


  validateEmail(text){
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(text).toLowerCase());
  }


  submit(e){
    if (e.key === "Enter") {
      if(this.validateEmail(this.state.email)){
        this.setState({ requred: false })
        this.send()
      } else {
        this.setState({ requred: true })
      }
    } else if(e.type === "click"){
      if(this.validateEmail(this.state.email)){
        this.setState({ requred: false })
        this.send()
      } else {
        this.setState({ requred: true })
      }
    }
  }

  send(){
    axios
      .post("https://narocevalnik.signaco.si/addtoemail", { email:this.state.email, url:"http://www.signaco.si" }, { crossdomain: true })
      .then(function(response) {
        console.log(response);
      })
      .catch(function(error) {
        console.log(error);
      });
    this.hide()
  }

  


  render() {
  const displayState = this.state.display ? styles.displayON : styles.displayOFF;
    
    return (
      <div className={css(styles.wrapper, displayState)}>
        <div className={css(styles.popup, displayState)}>
          <img alt="close" onClick={this.hide} className={css(styles.close)} src="../assets/icons/close.png" />
          <h2 className={css(styles.title)}>signaco obveščevalnik</h2>
          <p className={css(styles.text)}>če želite da vas obveščamo o novostih nam prosim zaupajte vaš email.</p>
          <input
            type="email"
            className={this.state.requred ? css(styles.boxTextInput, styles.boxTextInputError) : css(styles.boxTextInput)}
            placeholder="Email"
            value={this.state.email}
            onChange={e => this.setState({ email: e.nativeEvent.target.value })}
          />
          <div onKeyDown={e => this.submit(e)} onClick={this.submit} className={css(styles.button)}>potrdi</div>
        </div>
      </div>
    );
  }
}


const styles = StyleSheet.create({
  title:{
    color: "#838383",
    fontSize: 40,
    fontWeight: 300,
    letterSpacing: 1.8,
    lineHeight: "56px",
  },
  text:{
    marginTop:12,
    marginBottom:20,
    maxWidth:440,
    fontSize: 14,
    lineHeight:1.4,
    color: "#707070",
  },
  wrapper:{
    margin:"0 auto",
    width:"100%",
    position:"fixed",
    boxShadow: "0 0 20px 0 rgba(89,89,89,0.2)",
    zIndex:"1000000",
    bottom:0,
    color:"#000",
    display:"flex",
    textAlign:"center",
    justifyContent:"center",
    alignItems:"center",
    background:"rgba(0,0,0,0.5)",
    left:0,
    top:0,
    right:0,
  },
  popup:{
    position:"relative",
    textAlign:"center",
    background:"#fff",
    padding:60,
  },
  displayON:{
    visibility:"visible",
  },
  displayOFF:{
    visibility:"hidden",
  },
  close:{
    position:"absolute",
    width:14,
    top:24,
    right:24,
    cursor:"pointer",
    zIndex:1000,
    '@media (max-width: 780px)': {
      bottom:29,
      right:20,
    },
    '@media (max-width: 540px)': { 
      bottom:39,
    },
  },


 contact5:{
    textAlign:"center",
    margin:"0 auto",
    marginTop:40,
    display:"flex",
    textDecoration:"none",
    borderRadius:20,
    paddingLeft:10,
    alignItems:"center",
    background:"#4CAF50",
    color: "#fff",
    fontSize: 16,
    transition:"0.3s",
    fontWeight: 500,
    height:40,
    width:190,
    justifyContent:"center",
    letterSpacing: 0.5,
    lineHeight: "32px",
    ":hover":{
      transform:"scale(1.05)",
    }
  },
  contact5Img:{
    width:27,
    paddingLeft:40,
  },

  boxTextInputError:{
    border: "solid 2px #cc0000",
  },
  

  boxTextInput: {
    width: "calc(100% - 54px)",
    margin: 12,
    height: 44,
    border: "solid 2px #F1F1F4",
    background: "#F1F1F4",
    borderRadius: 0,
    paddingLeft: 12,
    fontSize: 14,
    paddingRight: 12,
    outlineColor: "#4CAF50",
    "@media (max-width: 650px)": {
      margin: 5,
      marginLeft: 0,
      marginRight: 0,
      width: "calc(100% - 34px)"
    }
  },

  button: {
    minWidth: 120,
    margin:"0 auto",
    width:120,
    position: "relative",
    display: "flex",
    transition: "0.1s ease-in-out",
    overflow: "hidden",
    flexDirection: "column",
    userSelect: "none",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "17px",
    background: "#fff",
    cursor: "pointer",
    textDecoration: "none",
    color: "#4CAF50",
    border: "2px solid #4CAF50",
    paddingRight: 24,
    paddingLeft: 24,
    height: 40,
    outlineColor: "#4CAF50",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 24,
    marginBottom: 0,
    ":hover": {
      color: "#fff",
      background: "#4CAF50"
    }
  },

});

export default SignacoNewsLetter;
