import autobind from 'core-decorators/lib/autobind';
import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import {Helmet} from "react-helmet";

@autobind
class Error extends React.Component {
  state = {
    website: "www.signaco.si",
    address: "Topole 51a, 1234 Mengeš",
    linkToCookies: "https://signaco.si/assets/cookiePolicy.pdf",
    company1: "signaco d.o.o.",
    company2: "signaco",
    company3: "Signaco",
    email: "info@signaco.si",
    date: "1.6.2018",
    service: "proizvodnjo prometnih in neprometnih znakov",
    service2: "proizvajanja prometnih in neprometnih znakov",
  }; 

render() {
    return (
      <div className={css(styles.wrapper)}>
        <Helmet>
          <title>Pogoji uporabe - signaco</title>
          <meta name="description" content="Podjetje za prodajo prometnih znakov, izdelujemo prometne znake, nosilne drogove, portale, sidra ter ostalo komunalno in urbano opremo, izdelava: prometni znaki" />
        </Helmet>
      	<div className={css(styles.titleMain)}>
      		Splošni pogoji uporabe in politika varstva osebnih podatkov
      	</div>
        <div className={css(styles.title)}>
          1. Področje uporabe
        </div>
        <div className={css(styles.text)}>
          Ti splošni pogoji uporabe in politika varstva osebnih podatkov se nanašajo na vse uporabnike in vse oblike uporabe spletne strani {this.state.website}, ki je v lasti {this.state.company1} ter določajo pogoje njene uporabe ter politiko varstva osebnih podatkov, ki ji sledimo. Uporabljeni pojmi:<div>1. "splošni pogoji" pomenijo te Splošne pogoje uporabe in politiko varstva osebnih podatkov;</div><div>2. "spletna stran" pomeni spletno stran, spletne dejavnosti in ostale spletne oziroma digitalne storitve oziroma aplikacije {this.state.company2} {this.state.website};</div><div>3. "uporabnik" predstavlja vsakega uporabnika oziroma obiskovalca spletne strani,</div><div>4. "{this.state.company2}" ali "{this.state.company3}" pomeni {this.state.company1}, {this.state.address}.</div>
        </div>
        <div className={css(styles.title)}>
          2.Seznanjenost s splošnimi pogoji
        </div>
        <div className={css(styles.text)}>
          Uporabnik potrjuje, da je z vstopom na spletno stran izvajalca skrbno prebral te Splošne pogoje uporabe spletne strani in da se z njimi strinja ter da jih sprejema v celoti. Če s pogoji uporabe ne soglašate, priporočamo, da te spletne strani ne uporabljate in jo zapustite.
        </div>
        <div className={css(styles.title)}>
          3.Uporaba spletne strani
        </div>
        <div className={css(styles.text)}>
          Podatki objavljeni na spletni strani in vsebine so namenjeni izključno informativni naravi. Z objavami in na podlagi objav za {this.state.company2} ne nastanejo nikakršne pravice ali obveznosti, razen če je to izrecno navedeno. Uporaba spletne strani je na lastno odgovornost.
        </div>
        <div className={css(styles.text)}>
          Ves čas si prizadevamo za točnost in pravilnost podatkov, objavljenih na spletni strani. Za morebitne jezikovne ali vsebinske pomanjkljivosti, točnost informacij, tehnične ali druge napake ali za pomanjkljivo ažurirane podatke na spletni strani, ne odgovorajamo. Če na spletni strani obstajajo povezave na druge spletne strani, ki niso v nikakršni povezavi z {this.state.company2}, ne prevzemamo nobene odgovornosti za obliko in vsebino podatkov, pridobljenih prek povezav, ki niso last izvajalca ter za politike varovanja podatkov in pravil uporabe teh strani. {this.state.company3} ne jamči, da bodo spletna stran ali njene podstrani (vključno z vsemi vsebinami) vedno na voljo, da bo uporaba spletne strani varna in neprekinjena. {this.state.company3} ni odgovorno za mrežne in telefonske povezave, vključno s pokritostjo območja ali prekinitvijo zveze, kot tudi ne za kakršno koli škodo, ki bi nastala zaradi uporabe spletne strani, kot so izgubljeni podatki, izguba programov ali za kakršno koli podobno škodo oziroma za škodo, ki bi izhajala iz uporabe, obstoja ali nemožnosti uporabe spletne strani ali vsebin objavljanih na spletni strani.
        </div>
        <div className={css(styles.text)}>
          {this.state.company3} ne odgovarja za zlorabe tretjih oseb oziroma uporabnikov.
        </div>
        <div className={css(styles.title)}>
          4.Avtorske pravice
        </div>
        <div className={css(styles.text)}>
          Vse materialne in moralne avtorske pravice vseh avtorskih del objavljenih na spletni strani pripadajo {this.state.company2}. Avtorska dela so: prispevki, besedila, fotografije, razporeditve, design, video, glasba, ilustracije, grafični elementi, znaki in znamke in podobno). Imetniki avtorske pravice na avtorskih delih objavljenih na spletni strani so lahko tudi zaposleni v {this.state.company2}, partnerji, pogodbeni sodelavci ali tretje osebe, kar je ustrezno označeno. 
        </div>
        <div className={css(styles.text)}>
          {this.state.company3} dovoljuje, da se vsebina spletne strani, kjer so avtorska dela, le prebira in pregleduje. {this.state.company3} prepoveduje uporabo avtorskih del podjetja v vseh oblikah (na primer: spreminjanje, javno prikazovanje, predvajanje, reprodukcijo), kakor tudi uporabo podatkov navedenih v teh delih, brez predhodno pridobljenega dovoljenja {this.state.company2}. Kadar je podano dovoljenje mora uporabnik jasno označiti, da gre za avtorsko delo {this.state.company1} in navesti vir pridobitve.
        </div>
        <div className={css(styles.title)}>
          5.Politika varstva osebnih podatkov
        </div>
        <div className={css(styles.title)}>
          5.1.Splošno
        </div>
        <div className={css(styles.text)}>
          Ob obisku spletne strani {this.state.website} ter njenih podstrani se na spletnem strežniku avtomatsko shranjuje dnevniška datoteka spletnega strežnika (npr. IP-številka - številka, ki identificira posamezni računalnik oziroma drugo napravo, povezano s spletom; različica brskalnika, čas obiska in podobno). Podrobneje je politika piškotkov opisana v Politiki piškotkov objavljeni tukaj. Te podatke obdelujemo za potrebe vodenja svoje statistike obiskov na spletni strani.
        </div>
        <div className={css(styles.text)}>
          Osebni podatki na spletni strani se zbirajo izključno za namene določene v teh Splošnih Pogojih in <a href={this.state.linkToCookies}>Politiki Piškotkov</a>, za katere se strinjate, da ste jih prebrali. Varovanje osebnih podatkov se zagotavlja skladno z zakonom, ki ureja varstvo osebnih podatkov, zakonom, ki ureja elektronske komunikacije in Splošno uredbo o varstvu osebnih podatkov (GDPR).
        </div>
        <div className={css(styles.text)}>
          Vse osebne podatke, ki jih posredujete, bo {this.state.company3} obravnavala zaupno in jih uporabila zgolj v namene, za katere ste jih posredovali. Če bo nastala potreba po nadaljnji obdelavi vaših podatkov za kak drug namen, vas bomo o tem predhodno obvestili in vas zaprosili za vaše soglasje.
        </div>
        <div className={css(styles.text)}>
          Če na spletni strani obstajajo povezave na druge spletne strani, ki niso v nikakršni povezavi z {this.state.company2}, ne prevzemamo nobene odgovornosti za zaščito vaših osebnih podatkov na teh spletnih straneh.
        </div>
        <div className={css(styles.text)}>
          Za pravilnost in točnost osebnih podatkov, ki jih preko spletne strani {this.state.website} pošljte {this.state.company2} ste odgovorni izključno sami in nam jih posredujete prostovoljno in na podlagi privolitve.
        </div>
        <div className={css(styles.text)}>
          Posredovanje osebnih podatkov ni pogoj za uporabo spletne strani {this.state.website}.
        </div>
        <div className={css(styles.title)}>
          5.2.E-novice
        </div>
        <div className={css(styles.text)}>
          V {this.state.company2} se trudimo uporabnikom podati aktualne informacije iz različnih področij, povezanih s {this.state.service} preko objav novic, člankov, mnenj, priporočil, informacij. Te informacije so zgolj informativne narave in so za {this.state.company2} nezavezujoče.
        </div>
        <div className={css(styles.text)}>
          Na naši spletni strani ali z izpolnitvijo fizičnih obrazcev se lahko naročite na prejemanje novic ter informacij iz različnih področij, ki vas utegnejo zanimati in s katerimi se ukvarja {this.state.company2} ter na obvestila o izobraževanjih in delavnicah, na katerih sodelujejo sodelavci {this.state.company2}, novice o {this.state.company2} ali drugih pomembnih dogodkih povezanih s področjem spletnega razvoja in oblikovanja, in sicer tako, da nam posredujete ime in priimek ter e- naslov. V primeru prijave vas lahko {this.state.company2} prek tega e-naslova obvešča o novostih na področju {this.state.service2}, novicah, publikacijah, predavanjih in izobraževanjih, ter vam posreduje druge informacije s področja {this.state.service2}, ki so aktualna in so del delovanja {this.state.company2}.
        </div>
        <div className={css(styles.text)}>
          Navedeni osebni podatki se obdelujejo na osnovi vašega soglasja, pri čemer lahko soglasje za prejemanje oglasnih sporočil kadar koli prekličete.
        </div>
        <div className={css(styles.title)}>
          5.3.Piškotki
        </div>
        <div className={css(styles.text)}>
          Spletna stran {this.state.website} uporablja piškotke, kar je opredeljeno v Politiki Piškotkov. Svetujemo, da se s Politiko piškotkov seznanite.
        </div>
        <div className={css(styles.title)}>
          5.4.Uporabniki podatkov
        </div>
        <div className={css(styles.text)}>
          {this.state.company3} bo podatke primarno uporabljala za namene opredeljene v teh Splošnih pogojih. Zavezujemo pa se, da pridobljenih osebnih podatkov ne bomo posredovali tretjim osebam. Vendar pa {this.state.company2} lahko zbrane podatke posreduje pogodbenim obdelovalcem z namenom zagotavljanja nekaterih storitev (na primer: administratorju in skrbniku spletne strani). Zavezujemo se, da vaših osebnih podatkov ne bomo posredovali ali prenesli v tretjo državo ali kakšni mednarodni organizaciji.
        </div>
        <div className={css(styles.title)}>
          5.5.Čas hrambe
        </div>
        <div className={css(styles.text)}>
          Vaše osebne podatke hranimo toliko časa, kolikor je potrebno, da se izpolni namen za katerega so bili zbrani in uporabljeni, in sicer se vaši kontaktni podatki za potrebe prejemanja e-novic hranijo do preklica. Čas hrambe za podatke pridobljene preko piškotkov in njihove uporabnike pa je razvidno iz <a href={this.state.linkToCookies}>Politiki Piškotkov</a>.
        </div>
        <div className={css(styles.text)}>
          Po izteku časa in skladno s tehničnimi možnostmi se osebni podatki izbrišejo.
        </div>
        <div className={css(styles.title)}>
          5.6.Pravice uporabnika
        </div>
        <div className={css(styles.text)}>
          {this.state.company2} zagotavlja uresničevanje vseh pravic uporabnikov v zvezi z varstvom osebnih podatkov skladno z veljavnimi predpisi.
        </div>
        <div className={css(styles.text)}>
          Od {this.state.company2} lahko kadar koli zahtevate:
            <div>- podatek o tem, ali se v zvezi z vami obdelujejo osebni podatki;</div>
            <div>- dostop do osebnih podatkov (vpogled in/ali prepis);</div>
            <div>- informacije v zvezi z obdelavo teh podatkov (namen obdelave, vrsta podatkov, uporabnikih podatkov, času hrambe);</div>
            <div>- kopijo osebnih podatkov;</div>
            <div>- popravek netočnih osebnih podatkov in dopolnitev nepopolnih osebnih podatkov;</div>
            <div>- izbris vseh osebnih podatkov ("pravica do pozabe");</div>
            <div>- omejitev obdelave osebnih podatkov;</div>
            <div>- prenos podatkov;</div>
            <div>- preklic privolitve.</div>

        </div>
        <div className={css(styles.text)}>
          Vaše pravice se izvajajo skladno z vsakokratno veljavnimi predpisi.
        </div>
        <div className={css(styles.title)}>
          6. Sprememba pogojev in kršenje pogojev
        </div>
        <div className={css(styles.text)}>
          Splošni pogoji se lahko spreminjajo brez izrecnega predhodnega opozorila, zato uporabnikom svetujemo, da so na morebitne spremembe pozorni, da bodo seznanjeni in obveščeni o veljavnih pogojih uporabe spletne strani ter podatki o varovanju zasebnosti. Za kakršne koli posledice sprememb Splošnih pogojev {this.state.company2} ne prevzema nobene odgovornosti. Na spletnih straneh bodo objavljeni vsakokratno veljavni splošni pogoji, sprejeti skladno z vsakokratno veljavnimi predpisi. Zadnja veljavna verzija splošnih pogojev je označena z navedbo datuma, od kdaj splošni pogoji veljajo na koncu dokumenta.
        </div>
        <div className={css(styles.title)}>
          7. Kontakt in prijava kršitev
        </div>
        <div className={css(styles.text)}>
          Za vse pripombe, vprašanja, pritožbe, kršitve ali uporabniške izkušnje povezane s spletno stranjo lahko naslovite na nas preko e-naslova: {this.state.email} ali po pošti na naslov {this.state.company1}, od {this.state.address}.
        </div>
        <div className={css(styles.title)}>
          8. Reševanje sporov in uporaba prava
        </div>
        <div className={css(styles.text)}>
          Za spore, ki bi izvirali iz uporabe te spletne strani ali e-novic, se bomo trudili, da se predmetni spori čim prej rešijo sporazumno. V kolikor to ne o mogoče je pristojno sodišče v Ljubljani. Uporablja se pravo Republike Slovenije.
        </div>
        <div className={css(styles.title)}>
          9.Veljavnost splošnih pogojev
        </div>
        <div className={css(styles.text)}>
          Ti splošni pogoji se uporabljajo od dneva sprejema in objave dalje do njihovega preklica oziroma do morebitne spremembe.
        </div>
        <div className={css(styles.text)}>
          Splošni pogoji veljajo od dne {this.state.date}.
        </div>
        <div className={css(styles.text)}>
          {this.state.company1}
        </div>
      	
      </div> 
    );
  }
}


const styles = StyleSheet.create({
  wrapper:{
    maxWidth: 1000,
    width: "calc(100% - 100px)",
    margin: "0 auto",
    marginTop:40,
    '@media (max-width: 580px)': {
      width: "calc(100% - 60px)",
    },
    '@media (max-width: 340px)': {
      width: "calc(100% - 40px)",
    },
   },
   titleMain:{
    color: "#838383",
    fontSize: 40,
    fontWeight: 300,
    letterSpacing: 1.8,
    lineHeight: "56px",
   },
  
  title:{
    fontSize:22,
    fontWeight:600,
    marginTop:30,
    marginBottom:20,
  },
  text:{
    fontSize:15,
    fontWeight:500,
    marginBottom:10,
    color:"#9B9B9B",
  },

 
});


export default Error;