import autobind from 'core-decorators/lib/autobind';
import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import Helmet from "react-helmet";

@autobind
class Catalog extends React.Component {

  render() {
    return (
      <div className={css(styles.wrapper)}>
        <Helmet>
          <title>Navodila - Signaco</title>
          <meta name="description" content="Podjetje za prodajo prometnih znakov, izdelujemo prometne znake, nosilne drogove, portale, sidra ter ostalo komunalno in urbano opremo, izdelava: prometni znaki"/>
        </Helmet>
        <div className={css(styles.innerWrapper)}>
          <h1 className={css(styles.title)}>
            navodila
          </h1>
          <a target="_blank" href="../../assets/navodila/NavodilaZaSestavo2017.pdf" className={css(styles.buttonWrapper)}>
            <div className={css(styles.buttonImg)} >
              <img alt="Prometni znak signaco" src="../assets/icons/svg/buttonCatalog.svg" className={css(styles.buttonImg)} />
            </div>
            <p className={css(styles.buttonText)}>
              Navodila za sestavo in postavitev prometnega znaka in tabel ter montažo drogov (po SIST EN 12899-1:2008)
            </p>
          </a>
          <a target="_blank" href="../../assets/navodila/NavodilaZaVzdrzevanje2017.pdf" className={css(styles.buttonWrapper)}>
            <div className={css(styles.buttonImg)} >
              <img alt="Prometni znaki Signaco" src="../assets/icons/svg/buttonCatalog.svg" className={css(styles.buttonImg)} />
            </div>
            <p className={css(styles.buttonText)}>
              Navodila za delovanje, vzdrževanje in čiščenje vertikalne signalizacije, nosilnih konstrukcij in pritrdilnega materiala (po SIST EN 12899-1:2008)
            </p>
          </a>
          <a target="_blank" href="../../assets/navodila/NavodilaZaVzdrzevanjeAntirosnihOgledal.pdf" className={css(styles.buttonWrapper)}>
            <div className={css(styles.buttonImg)} >
              <img alt="Prometni znaki Signaco" src="../assets/icons/svg/buttonCatalog.svg" className={css(styles.buttonImg)} />
            </div>
            <p className={css(styles.buttonText)}>
              Navodila za vzdrževanje antirosnih ogledal
            </p>
          </a>
          <a target="_blank" href="../../assets/navodila/Navodilo-za-montazo-lamele-VP-1300-1600x300.pdf" className={css(styles.buttonWrapper)}>
            <div className={css(styles.buttonImg)} >
              <img alt="Prometna signalizacija Signaco" src="../assets/icons/svg/buttonCatalog.svg" className={css(styles.buttonImg)} />
            </div>
            <div className={css(styles.buttonText)}>
              Navodila za montažo lamele
            </div>
          </a>
          <a target="_blank" href="../../assets/navodila/PomenoZnakNaNalepkiZGlavoSignaco.pdf" className={css(styles.buttonWrapper)}>
            <div className={css(styles.buttonImg)} >
              <img alt="Prometni znaki Pomen oznak Na Nalepki Z Glavo Signaco" src="../assets/icons/svg/buttonCatalog.svg" className={css(styles.buttonImg)} />
            </div>
            <div className={css(styles.buttonText)}>
              Pomen oznak na nalepki z glavo Signaco
            </div>
          </a>


        </div>
      </div>
    );
  }
}


const styles = StyleSheet.create({
	wrapper:{
    margin:"40px auto",
    maxWidth:1000,
    width:"calc(100% - 100px)",
    '@media (max-width: 580px)': {
      width: "calc(100% - 60px)",
    },
    '@media (max-width: 340px)': {
      width: "calc(100% - 40px)",
    },
  },
 
  innerWrapper:{
    maxWidth:800,
    
    '@media (max-width: 500px)': {
      
    },
  },
  title:{
    color: "#838383",
    fontSize: 40,
    fontWeight: 300,
    letterSpacing: 1.8,
    lineHeight: "56px",
  },
  text:{
    marginTop:30,
    marginBottom:30,
    fontSize: 17,
    lineHeight:1.4,
    color: "#707070",
  },
  buttonsWrapper:{
    maxWidth:500,
    display:"flex",
    
  },
  buttonWrapper:{
    textDecoration:"none",
    display:"flex",
    marginTop:30,
    alignItems:"center",

    justifyContent: 'felx-start',
    cursor:"pointer",
    '@media (max-width: 413px)': {
      marginTop:20,
    },
  },
  buttonImg:{
    width:50,
    marginRight:10,
  },
  buttonText:{
    textDecoration:"none",
    color: "#595959",
    fontSize: 16,
    fontWeight: 400,
  },

});

export default Catalog;
