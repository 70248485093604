import autobind from 'core-decorators/lib/autobind';
import React from 'react';
import { StyleSheet, css } from 'aphrodite';

@autobind
class NewPost extends React.Component {

  render() {
    return (
      <div className={css(styles.innerWrapper)}>
        <div className={css(styles.title)}>
          {this.props.title}
        </div>
        <div className={css(styles.date)}>
          {this.props.date}
        </div>
        <p className={css(styles.text)} dangerouslySetInnerHTML={{__html: this.props.text}}>
        </p>
        <p className={css(styles.author)}>
          {this.props.author}
        </p>
        
      </div>
    );
  }
}


const styles = StyleSheet.create({
  innerWrapper:{
    maxWidth: 700,
    marginTop:50,
    marginBottom:50,

  },
  title:{
    fontSize: 40,
    color: "#838383",
    letterSpacing: 1.8,
    fontWeight: 300,
    '@media (max-width: 750px)': {
      fontSize: 35,
    },
    '@media (max-width: 670px)': {
      fontSize: 28,
    },
  },
  date:{
    fontSize: 12,
    color: "#4CAF50",
    fontWeight: 400,
    marginTop:5,
    
  },
  text:{
    marginTop:30,
    marginBottom:30,
    fontSize: 17,
    lineHeight:1.4,
    color: "#707070",
    // lineHeight:1.7,
    // letterSpacing:0.5,
    // marginTop:20,
    // maxWidth:550,
    // fontWeight: 300,
    // color: "#000",
    // fontSize: 16,
    // lineHeight: "18px",
  },
  author:{
    color: "#595959",
    fontWeight: 400,
    fontSize: 12,
  },
  img:{
    maxWidth:600,
    width:"100%",
  },

});

export default NewPost;
