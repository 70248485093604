// @flow
import autobind from 'core-decorators/lib/autobind';
import React from 'react';
import BrowserRouter from 'react-router-dom/BrowserRouter';

import Routes from './Routes';
import Footer from './components/Footer';
import SideNavigation from './components/SideNavigation';
import NavigationFixed from './components/NavigationFixed';
import TopNavigation from './components/TopNavigation';
import { StyleSheet, css } from 'aphrodite';
import CookiePopup from './components/CookiePopup';
import NewPopup from './components/NewPopup';
import SignacoNewsLetter from './components/SignacoNewsLetter';
import Link from 'react-router-dom/Link';


@autobind
class App extends React.Component {
  state = {
    transition: false,
  };

  handleClick(){
    this.setState({transition: true});
    setTimeout(() => this.setState({ transition: false}), 500);
  }
  handleOpen(){
    this.child.handleOpen();
  }


  render() {
    const transition = this.state.transition ? styles.transition : null;
    return (
      <BrowserRouter onUpdate={this.fun}>
        <div className={css(styles.wrapper)}>

          
           
          <NavigationFixed onClick={this.handleClick} ref={instance => { this.child = instance; }}/>


          <div className={css(styles.flex)}>
            <SideNavigation onClick={this.handleClick} />
            <div className={css(styles.width100, transition)}>
              <TopNavigation onOpen={this.handleOpen} />
              <div className={css(styles.minHeight)}>
                <Routes />
              </div>
              <Footer />
            </div>
          </div>

          <div className={css(styles.flex2)}>
            <div className={css(styles.width100, transition)}>
              <TopNavigation onOpen={this.handleOpen} />
              <div className={css(styles.minHeight)}>
                <Routes />
              </div>
              <Footer />
            </div>
          </div>

          <CookiePopup />
          <NewPopup />
          <SignacoNewsLetter />
       
        <div style={{display:"none"}}>
          <Link to="/znaki"></Link>
          <Link to="/znaki/prometni-znaki"></Link>
          <Link to="/znaki/vertikalna-signalizacija"></Link>
          <Link to="/znaki/horizontalna-signalizacija"></Link>
          <Link to="/znaki/ostala-signalizacija"></Link>
          <Link to="/znaki/prometna-oprema"></Link>
          <Link to="/znaki/druga-oprema"></Link>
          <Link to="/znaki/katalogi"></Link>
          <Link to="/znaki/navodila"></Link>
          <Link to="/znaki/galerija-izdelkov"></Link>

          <Link to="/izdelki"></Link>
          <Link to="/izdelki/prometni-znaki"></Link>
          <Link to="/izdelki/vertikalna-signalizacija"></Link>
          <Link to="/izdelki/horizontalna-signalizacija"></Link>
          <Link to="/izdelki/ostala-signalizacija"></Link>
          <Link to="/izdelki/prometna-oprema"></Link>
          <Link to="/izdelki/druga-oprema"></Link>
          <Link to="/izdelki/katalogi"></Link>
          <Link to="/izdelki/navodila"></Link>
          <Link to="/izdelki/galerija-izdelkov"></Link>

          <Link to="/proizvodnja"></Link>
          <Link to="/o-podjetju"></Link>
          <Link to="/kontakt"></Link>
          <Link to="/novice"></Link>
          <Link to="/"></Link>
          <Link to="/znak-po-meri"></Link>
          <Link to="/mojznak"></Link>
          <Link to="/pznaki"></Link>
          <Link to="/prometni-znaki"></Link>
          <Link to="/obvezno"></Link>
          <Link to="/certifikati"></Link>
          <Link to="/javno3"></Link>
          <Link to="/dopolni"></Link>
          <Link to="/obvestila"></Link>
        </div>
        </div>
      </BrowserRouter>
    );
  }
}
const transition = {
    '0%': { opacity: 1, },
    '30%': { opacity: 0.1, },
    '50%': { opacity: 0, },
    '70%': { opacity: 0.1, },
    '100%': { opacity: 1, },
};
const styles = StyleSheet.create({
  wrapper: {

    margin:"0 auto",
    paddingBottom:0,
    position:"relative",

  },
  minHeight:{
    minHeight:"calc(100vh - 392px)",
  },
  width100:{
    width:"100%",
  },
  flex:{
    display:"flex",
    width:"100%",
    position:"relative",
    '@media (max-width: 1090px)': {
      display:"none",
    },
  },
  flex2:{  
    '@media (min-width: 1091px)': {
      display:"none",
    },
  },
  flexChild1: {

  },
  flexChild2: {

  },
  transition: {
    animationName: transition,
    animationDuration: "0.5s",
    animationTimingFunction: "ease-in-out",
  }

});

export default App;
