import autobind from 'core-decorators/lib/autobind';
import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import Helmet from "react-helmet";

import CatalogMini from '../ProductsPages/productsComponents/CatalogMini.js';

@autobind
class CatalogsPage extends React.Component {
  props: Props;
  state = {
    open1:false,
    open2:false,
    open3:false,
  };

  handleClick1(){
    this.setState({open1: !this.state.open1, open2: false, open3: false});
  }
  handleClick2(){
    this.setState({open1: false, open2: !this.state.open2, open3: false});
  }
  handleClick3(){
    this.setState({open1: false, open2: false, open3: !this.state.open3});
  }


  render() {
    const catalogState1 = this.state.open1 ? styles.imgWrapperFocus : null;
    const catalogState2 = this.state.open2 ? styles.imgWrapperFocus : null;
    const catalogState3 = this.state.open3 ? styles.imgWrapperFocus : null;

    const catalogStateImg1 = this.state.open1 ? styles.imgFocus : null;
    const catalogStateImg2 = this.state.open2 ? styles.imgFocus : null;
    const catalogStateImg3 = this.state.open3 ? styles.imgFocus : null;

    const miniWrapperState1 = this.state.open1 ? styles.miniWrapperOpen : styles.miniWrapperClose;
    const miniWrapperState2 = this.state.open2 ? styles.miniWrapperOpen : styles.miniWrapperClose;
    const miniWrapperState3 = this.state.open3 ? styles.miniWrapperOpen : styles.miniWrapperClose;

    return (
      <div className={css(styles.wrapper)}>
        <Helmet>
          <title>Katalogi - Signaco</title>
          <meta name="description" content="Podjetje za prodajo prometnih znakov, izdelujemo prometne znake, nosilne drogove, portale, sidra ter ostalo komunalno in urbano opremo, prometna signalizacija"/>
        </Helmet>
        <h1 className={css(styles.title2)}>
          katalogi
        </h1>
        <div className={css(styles.innerWrapper1)}>
          <div className={css(styles.line1)}>

            <div className={css(styles.catalogWrapper)}>
              <div className={css(styles.imgWrapperNoneBorder)}>
                <div style={{backgroundImage:"url(../assets/katalogiImg/I_00_Prometnasign.png)"}}  className={css(styles.imgNoneBorder)} />
              </div>
              <div className={css(styles.title)}>
                I Prometna signalizacija
              </div>
              <a rel="noopener noreferrer" target="_blank" href="../../assets/katalogi/I_00_Prometnasign.pdf" className={css(styles.buttonWrapper)}>
                <img alt="prometni znaki katalog signaco.si" src="../assets/icons/svg/buttonCatalog.svg" className={css(styles.buttonImg)} />
                <div className={css(styles.buttonText)}>
                  KATALOG
                </div>
              </a>
            </div>

            <div className={css(styles.catalogWrapper)}>
              <div className={css(styles.imgWrapperNoneBorder)}>
                <div style={{backgroundImage:"url(../assets/katalogiImg/I_02_Turisticnasign.png)"}} className={css(styles.imgNoneBorder)} />
              </div>
              <div className={css(styles.title)}>
                I/2 Turistična in druga obvestilna signalizacija
              </div>
              <a rel="noopener noreferrer" target="_blank" href="../../assets/katalogi/I_02_Turisticnasign.pdf" className={css(styles.buttonWrapper)}>
                <img alt="prometni znaki katalog signaco.si" src="../assets/icons/svg/buttonCatalog.svg" className={css(styles.buttonImg)} />
                <div className={css(styles.buttonText)}>
                  KATALOG
                </div>
              </a>
            </div>

            <div className={css(styles.catalogWrapper)}>
              <div className={css(styles.imgWrapperNoneBorder)}>
                <div style={{backgroundImage:"url(../assets/katalogiImg/II_01_Zeleznice.png)"}} className={css(styles.imgNoneBorder)} />
              </div>
              <div className={css(styles.title)}>
                II / 1 Železniška prometna signalizacija
              </div>
              <a rel="noopener noreferrer" target="_blank" href="../../assets/katalogi/II_01_Zeleznice.pdf" className={css(styles.buttonWrapper)}>
                <img alt="prometni znaki katalog signaco.si" src="../assets/icons/svg/buttonCatalog.svg" className={css(styles.buttonImg)} />
                <div className={css(styles.buttonText)}>
                  KATALOG
                </div>
              </a>
            </div>

            <div className={css(styles.catalogWrapper)}>
              <div className={css(styles.imgWrapperNoneBorder)}>
                <div style={{backgroundImage:"url(../assets/katalogiImg/II_02_Vodnepoti.png)"}} className={css(styles.imgNoneBorder)} />
              </div>
              <div className={css(styles.title)}>
                II / 2 Vodne poti
              </div>
              <a rel="noopener noreferrer" target="_blank" href="../../assets/katalogi/II_02_Vodnepoti.pdf" className={css(styles.buttonWrapper)}>
                <img alt="prometni znaki katalog signaco.si" src="../assets/icons/svg/buttonCatalog.svg" className={css(styles.buttonImg)} />
                <div className={css(styles.buttonText)}>
                  KATALOG
                </div>
              </a>
            </div>

            <div className={css(styles.catalogWrapper)}>
              <div onClick={this.handleClick1} className={css(styles.imgWrapper, catalogState1)}>
                <div className={css(styles.img1, catalogStateImg1)} />
              </div>
              <div className={css(styles.title)}>
                III Varnostni znaki in neprometne table
              </div>
              <a rel="noopener noreferrer" target="_blank" href="../../assets/katalogi/III_00_VARNOSTNIZNAKIINNEPROMTABLE.pdf" className={css(styles.buttonWrapper)}>
                <img alt="prometni znaki katalog signaco.si" src="../assets/icons/svg/buttonCatalog.svg" className={css(styles.buttonImg)} />
                <div className={css(styles.buttonText)}>
                  KATALOG
                </div>
              </a>
            </div>

            

            <div className={css(styles.catalogWrapper)}>
              <div onClick={this.handleClick2} className={css(styles.imgWrapper, catalogState2)}>
                <div className={css(styles.img2, catalogStateImg2)} />
              </div>
              <div className={css(styles.title)}>
                IV Znaki za smučišča, poti in znamenitosti
              </div>
              <a rel="noopener noreferrer" target="_blank" href="../../assets/katalogi/IV_00_SMUCISCAPOTIZNAMENITOSTI.pdf" className={css(styles.buttonWrapper)}>
                <img alt="prometni znaki katalog signaco.si" src="../assets/icons/svg/buttonCatalog.svg" className={css(styles.buttonImg)} />
                <div className={css(styles.buttonText)}>
                  KATALOG
                </div>
              </a>
            </div>

            

            <div className={css(styles.catalogWrapper)}>
              <div onClick={this.handleClick3} className={css(styles.imgWrapper, catalogState3)}>
                <div className={css(styles.img3, catalogStateImg3)} />
              </div>
              <div className={css(styles.title)}>
                V Prometna oprema
              </div>
              <a rel="noopener noreferrer" target="_blank" href="../../assets/katalogi/V_00_PROMETNAOPREMA.pdf" className={css(styles.buttonWrapper)}>
                <img alt="prometni znaki katalog signaco.si" src="../assets/icons/svg/buttonCatalog.svg" className={css(styles.buttonImg)} />
                <div className={css(styles.buttonText)}>
                  KATALOG
                </div>
              </a>
            </div>

            

            <div className={css(styles.catalogWrapper)}>
              <div className={css(styles.imgWrapperNoneBorder)}>
                <div style={{backgroundImage:"url(../assets/katalogiImg/VI_Horizontalnasign.png)"}} className={css(styles.imgNoneBorder)} />
              </div>
              <div className={css(styles.title)}>
                VI Horizontalna signalizacija
              </div>
              <a rel="noopener noreferrer" target="_blank" href="../../assets/katalogi/VI_Horizontalnasign.pdf" className={css(styles.buttonWrapper)}>
                <img alt="prometni znaki katalog signaco.si" src="../assets/icons/svg/buttonCatalog.svg" className={css(styles.buttonImg)} />
                <div className={css(styles.buttonText)}>
                  KATALOG
                </div>
              </a>
            </div>

          </div>
          <div className={css(styles.line2)}>
            <div className={css(styles.miniWrapperWrapper, miniWrapperState1)}>
              <div className={css(styles.miniWrapper)}>
                <CatalogMini 
                  title="III / 1 Gradbiščne table"
                  katalogHref="../../assets/katalogi/III_01_Gradbiscnetable.pdf"
                  imgSrc="../assets/katalogiImg/III_01_Gradbiscne table.png"
                />
                <CatalogMini 
                  title="III / 2 Varnostni znaki"
                  katalogHref="../../assets/katalogi/III_02_Varnostniznaki.pdf"
                  imgSrc="../assets/katalogiImg/III_02_Varnostni znaki.png"
                />
                <CatalogMini 
                  title="III / 3 Reklamne tablei"
                  katalogHref="../../assets/katalogi/III_03_Reklamnetable.pdf"
                  imgSrc="../assets/katalogiImg/III_03_Reklamne table.png"
                />
                <CatalogMini 
                  title="III / 4 Druge obvestilne table"
                  katalogHref="../../assets/katalogi/III_04_Drugeobvestilnetable.pdf"
                  imgSrc="../assets/katalogiImg/III_04_Druge obvestilne table.png"
                />
              </div>
            </div>
            <div className={css(styles.miniWrapperWrapper, miniWrapperState2)}>
              <div className={css(styles.miniWrapper)}>
                <CatalogMini 
                  title="IV / 1 Znaki za smučišča"
                  katalogHref="../../assets/katalogi/IV_01_Smucisca.pdf"
                  imgSrc="../assets/katalogiImg/IV_01_Smucisca.png"
                />
                <CatalogMini 
                  title="IV / 2 Znaki za planinske in pohodniške poti"
                  katalogHref="../../assets/katalogi/IV_02_Planinskeinpohodniskepoti.pdf"
                  imgSrc="../assets/katalogiImg/IV_02_Planinske in pohodniske poti.png"
                />
                <CatalogMini 
                  title="IV / 3 Znaki za označevanje naravnih vrednot"
                  katalogHref="../../assets/katalogi/IV_03_Naravnevrednote.pdf"
                  imgSrc="../assets/katalogiImg/IV_03_Naravne vrednote.png"
                />
                <CatalogMini 
                  title="IV / 4 Znaki za označevanje nepremičnih kulturnih spomenikov"
                  katalogHref="../../assets/katalogi/IV_04_Kulturnispomeniki.pdf"
                  imgSrc="../assets/katalogiImg/IV_04_Kulturni spomeniki.png"
                />
              </div>
            </div>
            <div className={css(styles.miniWrapperWrapper, miniWrapperState3)}>
              <div className={css(styles.miniWrapper)}>
                <CatalogMini 
                  title="V / 1 Cestna oprema"
                  katalogHref="../../assets/katalogi/V_01_Cestnaoprema.pdf"
                  imgSrc="../assets/katalogiImg/V_01_Cestna oprema.png"
                />
                <CatalogMini 
                  title="V / 2 Kovinska oprema"
                  katalogHref="../../assets/katalogi/V_02_Kovinskaoprema.pdf"
                  imgSrc="../assets/katalogiImg/V_02_Kovinska oprema.png"
                />
                <CatalogMini 
                  title="V / 3 Urbana oprema"
                  katalogHref="../../assets/katalogi/V_03_Urbanaoprema.pdf"
                  imgSrc="../assets/katalogiImg/V_03_Urbana oprema.png"
                />
                <CatalogMini 
                  title="V / 4 Varovalne in odbojne ograje"
                  katalogHref="../../assets/katalogi/V_04_Ograje.pdf"
                  imgSrc="../assets/katalogiImg/V_04_Ograje.png"
                />
              </div>
            </div>
          </div>

          <div className={css(styles.line1)}>
            <div className={css(styles.catalogWrapper)}>
              <div className={css(styles.imgWrapperNoneBorder)}>
                <div style={{backgroundImage:"url(../assets/katalogiImg/VII_Znakiponarocilu.png)"}} className={css(styles.imgNoneBorder)} />
              </div>
              <div className={css(styles.title)}>
                VII Znaki po naročilu
              </div>
              <a rel="noopener noreferrer" target="_blank" href="../../assets/katalogi/VII_Znakiponarocilu.pdf" className={css(styles.buttonWrapper)}>
                <img alt="prometni znaki katalog signaco.si" src="../assets/icons/svg/buttonCatalog.svg" className={css(styles.buttonImg)} />
                <div className={css(styles.buttonText)}>
                  KATALOG
                </div>
              </a>
            </div>

            <div className={css(styles.catalogWrapper)}>
              <div className={css(styles.imgWrapperNoneBorder)}>
                <div style={{backgroundImage:"url(../assets/katalogiImg/VIII_Opremavozil.png)"}} className={css(styles.imgNoneBorder)} />
              </div>
              <div className={css(styles.title)}>
                VIII Oprema vozil
              </div>
              <a rel="noopener noreferrer" target="_blank" href="../../assets/katalogi/VIII_Opremavozil.pdf" className={css(styles.buttonWrapper)}>
                <img alt="prometni znaki katalog signaco.si" src="../assets/icons/svg/buttonCatalog.svg" className={css(styles.buttonImg)} />
                <div className={css(styles.buttonText)}>
                  KATALOG
                </div>
              </a>
            </div>

            <div className={css(styles.catalogWrapper)}>
              <div className={css(styles.imgWrapperNoneBorder)}>
                <div style={{backgroundImage:"url(../assets/katalogiImg/IX_Spreji.png)"}} className={css(styles.imgNoneBorder)} />
              </div>
              <div className={css(styles.title)}>
                IX Spreji za označevanje
              </div>
              <a rel="noopener noreferrer" target="_blank" href="../../assets/katalogi/IX_Spreji.pdf" className={css(styles.buttonWrapper)}>
                <img alt="prometni znaki katalog signaco.si" src="../assets/icons/svg/buttonCatalog.svg" className={css(styles.buttonImg)} />
                <div className={css(styles.buttonText)}>
                  KATALOG
                </div>
              </a>
            </div>

            <div className={css(styles.catalogWrapper)}>
              <div className={css(styles.imgWrapperNoneBorder)}>
                <div style={{backgroundImage:"url(../assets/katalogiImg/X_Drugetalneoznacbe.png)"}} className={css(styles.imgNoneBorder)} />
              </div>
              <div className={css(styles.title)}>
                X Druge talne označbe
              </div>
              <a rel="noopener noreferrer" target="_blank" href="../../assets/katalogi/X_Drugetalneoznacbe.pdf" className={css(styles.buttonWrapper)}>
                <img alt="prometni znaki katalog signaco.si" src="../assets/icons/svg/buttonCatalog.svg" className={css(styles.buttonImg)} />
                <div className={css(styles.buttonText)}>
                  KATALOG
                </div>
              </a>
            </div>

          </div>

          
        </div>

























        <div className={css(styles.innerWrapper2)}>
          <div className={css(styles.line1)}>

            <div className={css(styles.catalogWrapper)}>
              <div className={css(styles.imgWrapperNoneBorder)}>
                <div style={{backgroundImage:"url(../assets/katalogiImg/I_00_Prometnasign.png)"}}  className={css(styles.imgNoneBorder)} />
              </div>
              <div className={css(styles.title)}>
                I Prometna signalizacija
              </div>
              <a rel="noopener noreferrer" target="_blank" href="../../assets/katalogi/I_00_Prometnasign.pdf" className={css(styles.buttonWrapper)}>
                <img alt="prometni znaki katalog signaco.si" src="../assets/icons/svg/buttonCatalog.svg" className={css(styles.buttonImg)} />
                <div className={css(styles.buttonText)}>
                  KATALOG
                </div>
              </a>
            </div>

            <div className={css(styles.catalogWrapper)}>
              <div className={css(styles.imgWrapperNoneBorder)}>
                <div style={{backgroundImage:"url(../assets/katalogiImg/I_02_Turisticnasign.png)"}} className={css(styles.imgNoneBorder)} />
              </div>
              <div className={css(styles.title)}>
                I/2 Turistična in druga obvestilna signalizacija
              </div>
              <a rel="noopener noreferrer" target="_blank" href="../../assets/katalogi/I_02_Turisticnasign.pdf" className={css(styles.buttonWrapper)}>
                <img alt="prometni znaki katalog signaco.si" src="../assets/icons/svg/buttonCatalog.svg" className={css(styles.buttonImg)} />
                <div className={css(styles.buttonText)}>
                  KATALOG
                </div>
              </a>
            </div>

            <div className={css(styles.catalogWrapper)}>
              <div className={css(styles.imgWrapperNoneBorder)}>
                <div style={{backgroundImage:"url(../assets/katalogiImg/II_01_Zeleznice.png)"}} className={css(styles.imgNoneBorder)} />
              </div>
              <div className={css(styles.title)}>
                II / 1 Železniška prometna signalizacija
              </div>
              <a rel="noopener noreferrer" target="_blank" href="../../assets/katalogi/II_01_Zeleznice.pdf" className={css(styles.buttonWrapper)}>
                <img alt="prometni znaki katalog signaco.si" src="../assets/icons/svg/buttonCatalog.svg" className={css(styles.buttonImg)} />
                <div className={css(styles.buttonText)}>
                  KATALOG
                </div>
              </a>
            </div>

             <div className={css(styles.catalogWrapper)}>
              <div className={css(styles.imgWrapperNoneBorder)}>
                <div style={{backgroundImage:"url(../assets/katalogiImg/II_02_Vodnepoti.png)"}} className={css(styles.imgNoneBorder)} />
              </div>
              <div className={css(styles.title)}>
                II / 2 Vodne poti
              </div>
              <a rel="noopener noreferrer" target="_blank" href="../../assets/katalogi/II_02_Vodnepoti.pdf" className={css(styles.buttonWrapper)}>
                <img alt="prometni znaki katalog signaco.si" src="../assets/icons/svg/buttonCatalog.svg" className={css(styles.buttonImg)} />
                <div className={css(styles.buttonText)}>
                  KATALOG
                </div>
              </a>
            </div>

            <div className={css(styles.catalogWrapper)}>
              <div onClick={this.handleClick1} className={css(styles.imgWrapper, catalogState1)}>
                <div className={css(styles.img1, catalogStateImg1)} />
              </div>
              <div className={css(styles.title)}>
                III Varnostni znaki in neprometne table
              </div>
              <a rel="noopener noreferrer" target="_blank" href="../../assets/katalogi/III_00_VARNOSTNIZNAKIINNEPROMTABLE.pdf" className={css(styles.buttonWrapper)}>
                <img alt="prometni znaki katalog signaco.si" src="../assets/icons/svg/buttonCatalog.svg" className={css(styles.buttonImg)} />
                <div className={css(styles.buttonText)}>
                  KATALOG
                </div>
              </a>
            </div>

            

            <div className={css(styles.catalogWrapper)}>
              <div onClick={this.handleClick2} className={css(styles.imgWrapper, catalogState2)}>
                <div className={css(styles.img2, catalogStateImg2)} />
              </div>
              <div className={css(styles.title)}>
                IV Znaki za smučišča, poti in znamenitosti
              </div>
              <a rel="noopener noreferrer" target="_blank" href="../../assets/katalogi/IV_00_SMUCISCAPOTIZNAMENITOSTI.pdf" className={css(styles.buttonWrapper)}>
                <img alt="prometni znaki katalog signaco.si" src="../assets/icons/svg/buttonCatalog.svg" className={css(styles.buttonImg)} />
                <div className={css(styles.buttonText)}>
                  KATALOG
                </div>
              </a>
            </div>

            </div>

          <div className={css(styles.line2)}>
            <div className={css(styles.miniWrapperWrapper, miniWrapperState1)}>
              <div className={css(styles.miniWrapper)}>
                <CatalogMini 
                  title="III / 1 Gradbiščne table"
                  katalogHref="../../assets/katalogi/III_01_Gradbiscnetable.pdf"
                  imgSrc="../assets/katalogiImg/III_01_Gradbiscne table.png"
                />
                <CatalogMini 
                  title="III / 2 Varnostni znaki"
                  katalogHref="../../assets/katalogi/III_02_Varnostniznaki.pdf"
                  imgSrc="../assets/katalogiImg/III_02_Varnostni znaki.png"
                />
                <CatalogMini 
                  title="III / 3 Reklamne tablei"
                  katalogHref="../../assets/katalogi/III_03_Reklamnetable.pdf"
                  imgSrc="../assets/katalogiImg/III_03_Reklamne table.png"
                />
                <CatalogMini 
                  title="III / 4 Druge obvestilne table"
                  katalogHref="../../assets/katalogi/III_04_Drugeobvestilnetable.pdf"
                  imgSrc="../assets/katalogiImg/III_04_Druge obvestilne table.png"
                />
              </div>
            </div>
            <div className={css(styles.miniWrapperWrapper, miniWrapperState2)}>
              <div className={css(styles.miniWrapper)}>
                <CatalogMini 
                  title="IV / 1 Znaki za smučišča"
                  katalogHref="../../assets/katalogi/IV_01_Smucisca.pdf"
                  imgSrc="../assets/katalogiImg/IV_01_Smucisca.png"
                />
                <CatalogMini 
                  title="IV / 2 Znaki za planinske in pohodniške poti"
                  katalogHref="../../assets/katalogi/IV_02_Planinskeinpohodniskepoti.pdf"
                  imgSrc="../assets/katalogiImg/IV_02_Planinske in pohodniske poti.png"
                />
                <CatalogMini 
                  title="IV / 3 Znaki za označevanje naravnih vrednot"
                  katalogHref="../../assets/katalogi/IV_03_Naravnevrednote.pdf"
                  imgSrc="../assets/katalogiImg/IV_03_Naravne vrednote.png"
                />
                <CatalogMini 
                  title="IV / 4 Znaki za označevanje nepremičnih kulturnih spomenikov"
                  katalogHref="../../assets/katalogi/IV_04_Kulturnispomeniki.pdf"
                  imgSrc="../assets/katalogiImg/IV_04_Kulturni spomeniki.png"
                />
              </div>
            </div>
          </div>

          <div className={css(styles.line1)}>

            <div className={css(styles.catalogWrapper)}>
              <div onClick={this.handleClick3} className={css(styles.imgWrapper, catalogState3)}>
                <div className={css(styles.img3, catalogStateImg3)} />
              </div>
              <div className={css(styles.title)}>
                V Prometna oprema
              </div>
              <a rel="noopener noreferrer" target="_blank" href="../../assets/katalogi/V_00_PROMETNAOPREMA.pdf" className={css(styles.buttonWrapper)}>
                <img alt="prometni znaki katalog signaco.si" src="../assets/icons/svg/buttonCatalog.svg" className={css(styles.buttonImg)} />
                <div className={css(styles.buttonText)}>
                  KATALOG
                </div>
              </a>
            </div>
          
            <div className={css(styles.catalogWrapper)}>
              <div className={css(styles.imgWrapperNoneBorder)}>
                <div style={{backgroundImage:"url(../assets/katalogiImg/VI_Horizontalnasign.png)"}} className={css(styles.imgNoneBorder)} />
              </div>
              <div className={css(styles.title)}>
                VI Horizontalna signalizacija
              </div>
              <a rel="noopener noreferrer" target="_blank" href="../../assets/katalogi/VI_Horizontalnasign.pdf" className={css(styles.buttonWrapper)}>
                <img alt="prometni znaki katalog signaco.si" src="../assets/icons/svg/buttonCatalog.svg" className={css(styles.buttonImg)} />
                <div className={css(styles.buttonText)}>
                  KATALOG
                </div>
              </a>
            </div>
          
            <div className={css(styles.catalogWrapper)}>
              <div className={css(styles.imgWrapperNoneBorder)}>
                <div style={{backgroundImage:"url(../assets/katalogiImg/VII_Znakiponarocilu.png)"}} className={css(styles.imgNoneBorder)} />
              </div>
              <div className={css(styles.title)}>
                VII Znaki po naročilu
              </div>
              <a rel="noopener noreferrer" target="_blank" href="../../assets/katalogi/VII_Znakiponarocilu.pdf" className={css(styles.buttonWrapper)}>
                <img alt="prometni znaki katalog signaco.si" src="../assets/icons/svg/buttonCatalog.svg" className={css(styles.buttonImg)} />
                <div className={css(styles.buttonText)}>
                  KATALOG
                </div>
              </a>
            </div>
          </div>
          <div className={css(styles.line2)}>
            <div className={css(styles.miniWrapperWrapper, miniWrapperState3)}>
              <div className={css(styles.miniWrapper)}>
                <CatalogMini 
                  title="V / 1 Cestna oprema"
                  katalogHref="../../assets/katalogi/V_01_Cestnaoprema.pdf"
                  imgSrc="../assets/katalogiImg/V_01_Cestna oprema.png"
                />
                <CatalogMini 
                  title="V / 2 Kovinska oprema"
                  katalogHref="../../assets/katalogi/V_02_Kovinskaoprema.pdf"
                  imgSrc="../assets/katalogiImg/V_02_Kovinska oprema.png"
                />
                <CatalogMini 
                  title="V / 3 Urbana oprema"
                  katalogHref="../../assets/katalogi/V_03_Urbanaoprema.pdf"
                  imgSrc="../assets/katalogiImg/V_03_Urbana oprema.png"
                />
                <CatalogMini 
                  title="V / 4 Varovalne in odbojne ograje"
                  katalogHref="../../assets/katalogi/V_04_Ograje.pdf"
                  imgSrc="../assets/katalogiImg/V_04_Ograje.png"
                />
              </div>
            </div>
          </div>

          <div className={css(styles.line1)}>
            <div className={css(styles.catalogWrapper)}>
              <div className={css(styles.imgWrapperNoneBorder)}>
                <div style={{backgroundImage:"url(../assets/katalogiImg/VIII_Opremavozil.png)"}} className={css(styles.imgNoneBorder)} />
              </div>
              <div className={css(styles.title)}>
                VIII Oprema vozil
              </div>
              <a rel="noopener noreferrer" target="_blank" href="../../assets/katalogi/VIII_Opremavozil.pdf" className={css(styles.buttonWrapper)}>
                <img alt="prometni znaki katalog signaco.si" src="../assets/icons/svg/buttonCatalog.svg" className={css(styles.buttonImg)} />
                <div className={css(styles.buttonText)}>
                  KATALOG
                </div>
              </a>
            </div>

            <div className={css(styles.catalogWrapper)}>
              <div className={css(styles.imgWrapperNoneBorder)}>
                <div style={{backgroundImage:"url(../assets/katalogiImg/IX_Spreji.png)"}} className={css(styles.imgNoneBorder)} />
              </div>
              <div className={css(styles.title)}>
                IX Spreji za označevanje
              </div>
              <a rel="noopener noreferrer" target="_blank" href="../../assets/katalogi/IX_Spreji.pdf" className={css(styles.buttonWrapper)}>
                <img alt="prometni znaki katalog signaco.si" src="../assets/icons/svg/buttonCatalog.svg" className={css(styles.buttonImg)} />
                <div className={css(styles.buttonText)}>
                  KATALOG
                </div>
              </a>
            </div>

            <div className={css(styles.catalogWrapper)}>
              <div className={css(styles.imgWrapperNoneBorder)}>
                <div style={{backgroundImage:"url(../assets/katalogiImg/X_Drugetalneoznacbe.png)"}} className={css(styles.imgNoneBorder)} />
              </div>
              <div className={css(styles.title)}>
                X Druge talne označbe
              </div>
              <a rel="noopener noreferrer" target="_blank" href="../../assets/katalogi/X_Drugetalneoznacbe.pdf" className={css(styles.buttonWrapper)}>
                <img alt="prometni znaki katalog signaco.si" src="../assets/icons/svg/buttonCatalog.svg" className={css(styles.buttonImg)} />
                <div className={css(styles.buttonText)}>
                  KATALOG
                </div>
              </a>
            </div>

          </div>

          
        </div>



























        <div className={css(styles.innerWrapper3)}>
          <div className={css(styles.line1)}>

            <div className={css(styles.catalogWrapper)}>
              <div className={css(styles.imgWrapperNoneBorder)}>
                <div style={{backgroundImage:"url(../assets/katalogiImg/I_00_Prometnasign.png)"}}  className={css(styles.imgNoneBorder)} />
              </div>
              <div className={css(styles.title)}>
                I Prometna signalizacija
              </div>
              <a rel="noopener noreferrer" target="_blank" href="../../assets/katalogi/I_00_Prometnasign.pdf" className={css(styles.buttonWrapper)}>
                <img alt="prometni znaki katalog signaco.si" src="../assets/icons/svg/buttonCatalog.svg" className={css(styles.buttonImg)} />
                <div className={css(styles.buttonText)}>
                  KATALOG
                </div>
              </a>
            </div>

            <div className={css(styles.catalogWrapper)}>
              <div className={css(styles.imgWrapperNoneBorder)}>
                <div style={{backgroundImage:"url(../assets/katalogiImg/I_02_Turisticnasign.png)"}} className={css(styles.imgNoneBorder)} />
              </div>
              <div className={css(styles.title)}>
                I/2 Turistična in druga obvestilna signalizacija
              </div>
              <a rel="noopener noreferrer" target="_blank" href="../../assets/katalogi/I_02_Turisticnasign.pdf" className={css(styles.buttonWrapper)}>
                <img alt="prometni znaki katalog signaco.si" src="../assets/icons/svg/buttonCatalog.svg" className={css(styles.buttonImg)} />
                <div className={css(styles.buttonText)}>
                  KATALOG
                </div>
              </a>
            </div>

            <div className={css(styles.catalogWrapper)}>
              <div className={css(styles.imgWrapperNoneBorder)}>
                <div style={{backgroundImage:"url(../assets/katalogiImg/II_01_Zeleznice.png)"}} className={css(styles.imgNoneBorder)} />
              </div>
              <div className={css(styles.title)}>
                II / 1 Železniška prometna signalizacija
              </div>
              <a rel="noopener noreferrer" target="_blank" href="../../assets/katalogi/II_01_Zeleznice.pdf" className={css(styles.buttonWrapper)}>
                <img alt="prometni znaki katalog signaco.si" src="../assets/icons/svg/buttonCatalog.svg" className={css(styles.buttonImg)} />
                <div className={css(styles.buttonText)}>
                  KATALOG
                </div>
              </a>
            </div>

            <div className={css(styles.catalogWrapper)}>
              <div className={css(styles.imgWrapperNoneBorder)}>
                <div style={{backgroundImage:"url(../assets/katalogiImg/II_02_Vodnepoti.png)"}} className={css(styles.imgNoneBorder)} />
              </div>
              <div className={css(styles.title)}>
                II / 2 Vodne poti
              </div>
              <a rel="noopener noreferrer" target="_blank" href="../../assets/katalogi/II_02_Vodnepoti.pdf" className={css(styles.buttonWrapper)}>
                <img alt="prometni znaki katalog signaco.si" src="../assets/icons/svg/buttonCatalog.svg" className={css(styles.buttonImg)} />
                <div className={css(styles.buttonText)}>
                  KATALOG
                </div>
              </a>
            </div>

            <div className={css(styles.catalogWrapper)}>
              <div onClick={this.handleClick1} className={css(styles.imgWrapper, catalogState1)}>
                <div className={css(styles.img1, catalogStateImg1)} />
              </div>
              <div className={css(styles.title)}>
                I PROMETNA SIGNALIZACIJA
              </div>
              <div className={css(styles.buttonWrapper)}>
                <img alt="prometni znaki katalog signaco.si" src="../assets/icons/svg/buttonCatalog.svg" className={css(styles.buttonImg)} />
                <a rel="noopener noreferrer" target="_blank" href="../../assets/katalogi/.pdf" className={css(styles.buttonText)}>
                  KATALOG
                </a>
              </div>
            </div>

            

            <div className={css(styles.catalogWrapper)}>
              <div onClick={this.handleClick2} className={css(styles.imgWrapper, catalogState2)}>
                <div className={css(styles.img2, catalogStateImg2)} />
              </div>
              <div className={css(styles.title)}>
                I PROMETNA SIGNALIZACIJA
              </div>
              <div className={css(styles.buttonWrapper)}>
                <img alt="prometni znaki katalog signaco.si" src="../assets/icons/svg/buttonCatalog.svg" className={css(styles.buttonImg)} />
                <a rel="noopener noreferrer" target="_blank" href="../../assets/katalogi/.pdf" className={css(styles.buttonText)}>
                  KATALOG
                </a>
              </div>
            </div>

            </div>

          <div className={css(styles.line2)}>
            <div className={css(styles.miniWrapperWrapper, miniWrapperState1)}>
              <div className={css(styles.miniWrapper)}>
                <CatalogMini 
                  title="III / 1 Gradbiščne table"
                  katalogHref="../../assets/katalogi/III_01_Gradbiscnetable.pdf"
                  imgSrc="../assets/katalogiImg/III_01_Gradbiscne table.png"
                />
                <CatalogMini 
                  title="III / 2 Varnostni znaki"
                  katalogHref="../../assets/katalogi/III_02_Varnostniznaki.pdf"
                  imgSrc="../assets/katalogiImg/III_02_Varnostni znaki.png"
                />
                <CatalogMini 
                  title="III / 3 Reklamne tablei"
                  katalogHref="../../assets/katalogi/III_03_Reklamnetable.pdf"
                  imgSrc="../assets/katalogiImg/III_03_Reklamne table.png"
                />
                <CatalogMini 
                  title="III / 4 Druge obvestilne table"
                  katalogHref="../../assets/katalogi/III_04_Drugeobvestilnetable.pdf"
                  imgSrc="../assets/katalogiImg/III_04_Druge obvestilne table.png"
                />
              </div>
            </div>
            <div className={css(styles.miniWrapperWrapper, miniWrapperState2)}>
              <div className={css(styles.miniWrapper)}>
                <CatalogMini 
                  title="IV / 1 Znaki za smučišča"
                  katalogHref="../../assets/katalogi/IV_01_Smucisca.pdf"
                  imgSrc="../assets/katalogiImg/IV_01_Smucisca.png"
                />
                <CatalogMini 
                  title="IV / 2 Znaki za planinske in pohodniške poti"
                  katalogHref="../../assets/katalogi/IV_02_Planinskeinpohodniskepoti.pdf"
                  imgSrc="../assets/katalogiImg/IV_02_Planinske in pohodniske poti.png"
                />
                <CatalogMini 
                  title="IV / 3 Znaki za označevanje naravnih vrednot"
                  katalogHref="../../assets/katalogi/IV_03_Naravnevrednote.pdf"
                  imgSrc="../assets/katalogiImg/IV_03_Naravne vrednote.png"
                />
                <CatalogMini 
                  title="IV / 4 Znaki za označevanje nepremičnih kulturnih spomenikov"
                  katalogHref="../../assets/katalogi/IV_04_Kulturnispomeniki.pdf"
                  imgSrc="../assets/katalogiImg/IV_04_Kulturni spomeniki.png"
                />
              </div>
            </div>
          </div>

          <div className={css(styles.line1)}>

            <div className={css(styles.catalogWrapper)}>
              <div onClick={this.handleClick3} className={css(styles.imgWrapper, catalogState3)}>
                <div className={css(styles.img3, catalogStateImg3)} />
              </div>
              <div className={css(styles.title)}>
                V Prometna oprema
              </div>
              <a rel="noopener noreferrer" target="_blank" href="../../assets/katalogi/V_00_PROMETNAOPREMA.pdf" className={css(styles.buttonWrapper)}>
                <img alt="prometni znaki katalog signaco.si" src="../assets/icons/svg/buttonCatalog.svg" className={css(styles.buttonImg)} />
                <div className={css(styles.buttonText)}>
                  KATALOG
                </div>
              </a>
            </div>
          
            <div className={css(styles.catalogWrapper)}>
              <div className={css(styles.imgWrapperNoneBorder)}>
                <div style={{backgroundImage:"url(../assets/katalogiImg/VI_Horizontalnasign.png)"}} className={css(styles.imgNoneBorder)} />
              </div>
              <div className={css(styles.title)}>
                VI Horizontalna signalizacija
              </div>
              <a rel="noopener noreferrer" target="_blank" href="../../assets/katalogi/VI_Horizontalnasign.pdf" className={css(styles.buttonWrapper)}>
                <img alt="prometni znaki katalog signaco.si" src="../assets/icons/svg/buttonCatalog.svg" className={css(styles.buttonImg)} />
                <div className={css(styles.buttonText)}>
                  KATALOG
                </div>
              </a>
            </div>
            </div>
          <div className={css(styles.line2)}>
            <div className={css(styles.miniWrapperWrapper, miniWrapperState3)}>
              <div className={css(styles.miniWrapper)}>
                <CatalogMini 
                  title="V / 1 Cestna oprema"
                  katalogHref="../../assets/katalogi/V_01_Cestnaoprema.pdf"
                  imgSrc="../assets/katalogiImg/V_01_Cestna oprema.png"
                />
                <CatalogMini 
                  title="V / 2 Kovinska oprema"
                  katalogHref="../../assets/katalogi/V_02_Kovinskaoprema.pdf"
                  imgSrc="../assets/katalogiImg/V_02_Kovinska oprema.png"
                />
                <CatalogMini 
                  title="V / 3 Urbana oprema"
                  katalogHref="../../assets/katalogi/V_03_Urbanaoprema.pdf"
                  imgSrc="../assets/katalogiImg/V_03_Urbana oprema.png"
                />
                <CatalogMini 
                  title="V / 4 Varovalne in odbojne ograje"
                  katalogHref="../../assets/katalogi/V_04_Ograje.pdf"
                  imgSrc="../assets/katalogiImg/V_04_Ograje.png"
                />
              </div>
            </div>
          </div>

          <div className={css(styles.line1)}>
          
            <div className={css(styles.catalogWrapper)}>
              <div className={css(styles.imgWrapperNoneBorder)}>
                <div style={{backgroundImage:"url(../assets/katalogiImg/VII_Znakiponarocilu.png)"}} className={css(styles.imgNoneBorder)} />
              </div>
              <div className={css(styles.title)}>
                VII Znaki po naročilu
              </div>
              <a rel="noopener noreferrer" target="_blank" href="../../assets/katalogi/VII_Znakiponarocilu.pdf" className={css(styles.buttonWrapper)}>
                <img alt="prometni znaki katalog signaco.si" src="../assets/icons/svg/buttonCatalog.svg" className={css(styles.buttonImg)} />
                <div className={css(styles.buttonText)}>
                  KATALOG
                </div>
              </a>
            </div>
          
            <div className={css(styles.catalogWrapper)}>
              <div className={css(styles.imgWrapperNoneBorder)}>
                <div style={{backgroundImage:"url(../assets/katalogiImg/VIII_Opremavozil.png)"}} className={css(styles.imgNoneBorder)} />
              </div>
              <div className={css(styles.title)}>
                VIII Oprema vozil
              </div>
              <a rel="noopener noreferrer" target="_blank" href="../../assets/katalogi/VIII_Opremavozil.pdf" className={css(styles.buttonWrapper)}>
                <img alt="prometni znaki katalog signaco.si" src="../assets/icons/svg/buttonCatalog.svg" className={css(styles.buttonImg)} />
                <div className={css(styles.buttonText)}>
                  KATALOG
                </div>
              </a>
            </div>

            <div className={css(styles.catalogWrapper)}>
              <div className={css(styles.imgWrapperNoneBorder)}>
                <div style={{backgroundImage:"url(../assets/katalogiImg/IX_Spreji.png)"}} className={css(styles.imgNoneBorder)} />
              </div>
              <div className={css(styles.title)}>
                IX Spreji za označevanje
              </div>
              <a rel="noopener noreferrer" target="_blank" href="../../assets/katalogi/IX_Spreji.pdf" className={css(styles.buttonWrapper)}>
                <img alt="prometni znaki katalog signaco.si" src="../assets/icons/svg/buttonCatalog.svg" className={css(styles.buttonImg)} />
                <div className={css(styles.buttonText)}>
                  KATALOG
                </div>
              </a>
            </div>

            <div className={css(styles.catalogWrapper)}>
              <div className={css(styles.imgWrapperNoneBorder)}>
                <div style={{backgroundImage:"url(../assets/katalogiImg/X_Drugetalneoznacbe.png)"}} className={css(styles.imgNoneBorder)} />
              </div>
              <div className={css(styles.title)}>
                X Druge talne označbe
              </div>
              <a rel="noopener noreferrer" target="_blank" href="../../assets/katalogi/X_Drugetalneoznacbe.pdf" className={css(styles.buttonWrapper)}>
                <img alt="prometni znaki katalog signaco.si" src="../assets/icons/svg/buttonCatalog.svg" className={css(styles.buttonImg)} />
                <div className={css(styles.buttonText)}>
                  KATALOG
                </div>
              </a>
            </div>

            

          </div>

          
        </div>
      </div>
    );
  }
}


const styles = StyleSheet.create({
	wrapper:{
    margin:"40px auto",
    maxWidth:1000,
    width:"calc(100% - 100px)",
	  '@media (max-width: 580px)': {
      width: "calc(100% - 60px)",
    },
    '@media (max-width: 340px)': {
      width: "calc(100% - 40px)",
    },
	},
  innerWrapper1:{
    maxWidth: 1000,
    marginTop:50,
    marginBottom:50,
    '@media (max-width: 850px)': {
      display:"none",
    },
  },
  innerWrapper2:{
    maxWidth: 1000,
    marginTop:50,
    marginBottom:50,
    '@media (min-width: 851px)': {
      display:"none",
    },
    '@media (max-width: 650px)': {
      display:"none",
    },
  },
  innerWrapper3:{
    display:"none",
    '@media (max-width: 650px)': {
      maxWidth: 1000,
      marginTop:50,
      marginBottom:50,
      display:"block",
    },
  },
  title2:{
    color: "#838383",
    fontSize: 40,
    fontWeight: 300,
    letterSpacing: 1.8,
    lineHeight: "56px",
  },
  line1:{
    display:"flex",
    flexWrap:"wrap",
  },
  line2:{
    display:"flex",
  },
  miniWrapperWrapper:{
    borderRadius:20,
    padding:20,
    paddingTop:30,
    paddingBottom:30,
    background:"#F4F4F4",
    width:"100%",
    margin:"0 auto",
    overflowX:"auto",
    marginTop:50,
    transition:"0.5s",
  },
  miniWrapperClose:{
    display:"none",
  },
  miniWrapperOpen:{
    display:"block",
  },
  miniWrapper:{
    display:"flex",
    alignItems:"center",
    minWidth:650,
    width:"100%",
    justifyContent:"space-between",
    '@media (max-width: 410px)': {
      minWidth:550,
    },
  },










  //catalog one
  catalogWrapper:{
    textDecoration:"none",
    display:"block",
    marginTop:50,
    width:"25%",
    textAlign:"center",
    '@media (max-width: 850px)': {
      width:"33.33%",
    },
    '@media (max-width: 650px)': {
      width:"50%",
    },
  },
  imgWrapperNoneBorder:{
    width:150,
    height:213,
    padding:8,
    margin:"0 auto",
    '@media (max-width: 450px)': {
      width:130,
      height:184,
    },
    '@media (max-width: 373px)': {
      width:110,
      height:156,
    },
  },

  imgNoneBorder:{
    width:150,
    
    height:213,
    borderRadius:32,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "cover",
    backgroundSize:"150px 213px",
    '@media (max-width: 450px)': {
      width:130,
      height:184,
      backgroundSize:"130px 184px",
    },
    '@media (max-width: 373px)': {
      width:110,
      height:156,
      backgroundSize:"110px 156px",
    },
  },

  imgWrapper:{
    border:"#4CAF50 solid 3px",
    borderRadius:32,
    width:150,
    height:213,
    padding:5,
    margin:"0 auto",
    transition:"0.1s linear 0.2s",
    ":hover":{
        borderRadius:38,
       padding:0,
       border:"#4CAF50 solid 8px",
    },
    '@media (max-width: 450px)': {
      width:130,
      height:184,
    },
    '@media (max-width: 373px)': {
      width:110,
      height:156,
    },
  },
  img1:{
    width:150,
    backgroundImage:"url(../assets/katalogiImg/III_00_VARNOSTNIZNAKIINNEPROMTABLE.png)",
    height:213,
    borderRadius:32,
    cursor:"pointer",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "cover",
    backgroundSize:"150px 213px",
    margin:"0 auto",
    transition:"0.2s",
    ":hover":{
      borderRadius:25,
      backgroundImage:"url(../assets/icons/buttonGreen.png)",
    },
    '@media (max-width: 450px)': {
      width:130,
      height:184,
      backgroundSize:"130px 184px",
    },
    '@media (max-width: 373px)': {
      width:110,
      height:156,
      backgroundSize:"110px 156px",
    },
  },
  img2:{
    width:150,
    backgroundImage:"url(../assets/katalogiImg/IV_00_SMUCISCAPOTIZNAMENITOSTI.png)",
    height:213,
    borderRadius:32,
    cursor:"pointer",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "cover",
    backgroundSize:"150px 213px",
    margin:"0 auto",
    transition:"0.2s",
    ":hover":{
      borderRadius:25,
      backgroundImage:"url(../assets/icons/buttonGreen.png)",
    },
    '@media (max-width: 450px)': {
      width:130,
      height:184,
      backgroundSize:"130px 184px",
    },
    '@media (max-width: 373px)': {
      width:110,
      height:156,
      backgroundSize:"110px 156px",
    },
  },
  img3:{
    width:150,
    backgroundImage:"url(../assets/katalogiImg/V_00_PROMETNAOPREMA.png)",
    height:213,
    borderRadius:32,
    cursor:"pointer",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "cover",
    backgroundSize:"150px 213px",
    margin:"0 auto",
    transition:"0.2s",
    ":hover":{
      borderRadius:25,
      backgroundImage:"url(../assets/icons/buttonGreen.png)",
    },
    '@media (max-width: 450px)': {
      width:130,
      height:184,
      backgroundSize:"130px 184px",
    },
    '@media (max-width: 373px)': {
      width:110,
      height:156,
      backgroundSize:"110px 156px",
    },
  },
  imgWrapperFocus:{
    padding:0,
    borderRadius:38,
    border:"#4CAF50 solid 8px",
  },
  imgFocus:{
    borderRadius:25,
    backgroundImage:"url(../assets/icons/buttonGreen.png)",
  },
  title:{
    height:32,
    color: "#737373",
    fontSize: 14,
    marginTop:17,
    marginBottom:17,
    fontWeight: 400,
    '@media (max-width: 450px)': {
      fontSize: 11,
      marginTop:15,
      marginBottom:14,
    },
    '@media (max-width: 373px)': {
      fontSize: 10,
      marginBottom:10,
    },
  },
  
  buttonWrapper:{
    textDecoration:"none",
    display:"flex",
    width:90,
    margin:"0 auto",
    alignItems:"center",
    justifyContent: 'center',
    cursor:"pointer",
    transition:"0.2s",
    ":hover":{
      transform:"scale(1.05)",
    },
  },
  buttonImg:{
    width:30,
    marginRight:10,
  },
  buttonText:{
    textDecoration:"none",
    color: "#595959",
    fontSize: 12,
  },
  //catalog one end
});

export default CatalogsPage;
