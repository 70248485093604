import autobind from 'core-decorators/lib/autobind';
import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import Helmet from "react-helmet";

import ContactForm from '../components/ContactForm';
import TeamSelection from "../selections/TeamSelection.js";

@autobind
class ContactPage extends React.Component {
  props: Props;
  state = {
    display:true,
  };


  handleClick(){
    this.setState({display: !this.state.display});
  }

  render() {
    const displayState = this.state.display ? styles.displayFalse : styles.displayTrue;
    const centerState = this.state.display ? styles.left : styles.center;
    return (
      <div className={css(styles.wrapper)}>
        <Helmet>
          <title>Kontakt - Signaco</title>
          <meta name="description" content="Podjetje za prodajo prometnih znakov, izdelujemo prometne znake, nosilne drogove, portale, sidra ter ostalo komunalno in urbano opremo, izdelava: prometni znaki"/>
        </Helmet>
        <div className={css(styles.innerWrapper)}>
          <div className={css(styles.container)}>
            <h1 className={css(styles.title)}>
              stopite v stik z nami
            </h1>
            <div className={css(styles.contacts, centerState)}>
              <div className={css(styles.contactsInfo, displayState)}>
                <div className={css(styles.contactsInfo_1)}>
                  <a href="mailto:info@signaco.si" onClick={()=>window.history.replaceState(null, null, window.location.origin+window.location.pathname+"?mail")} className={css(styles.contactsMail)}>info@signaco.si</a>
                  <a href="tel:+386 (0)1 7 23 09 73" className={css(styles.contactsPhone)} onClick={()=>window.history.replaceState(null, null, window.location.origin+window.location.pathname+"?tel")}>+386 (0)1 7 23 09 73</a>
                  
                </div>
                <div className={css(styles.contactsInfo_2)}>
                  <div className={css(styles.contactsFax)}>Fax: +386 (0) 1 723 0975</div>
                  <div className={css(styles.contactsOther)}>Davčna številka: SI 16535740</div>
                  <div className={css(styles.contactsOther)}>Matična številka: 5938961</div>
                  <div className={css(styles.contactsOther)}>TRR: SI56 0230 9001 9758 862 (NLB d.d.)</div>
                </div>
                <div className={css(styles.contactsInfo_map)}>
                  <img className={css(styles.img)} src="../assets/icons/svg/placeholder-filled-point.svg" alt="map_icon" />
                  <div className={css(styles.contactsInfo_map_text)}>Topole 51a, 1234 Mengeš</div>
                </div>
              </div>

              <div className={css(styles.contacts_form)}>
                <ContactForm onClick={this.handleClick} location="contact" />
              </div>
            </div>
            <div className={css(styles.title)}>
              ekipa
            </div>
            <div className={css(styles.flexWrapper)}>
              <TeamSelection 
                img="url(../assets/zaposleni/Ales.jpg)" 
                emailHref="mailto:info@signaco.si" 
                email="info@signaco.si"
                tel2="+ 386 (0) 1 723 09 73" 
                telHref2="tel:+ 386 (0) 1 723 09 73" 
                tel3="+ 386 (0) 1 723 09 74" 
                telHref3="tel:+ 386 (0) 1 723 09 74" 
                name="Aleš Babnik" 
                title="Direktor" 
                education=""
              />

              <TeamSelection 
                img="url(../assets/zaposleni/Zeljko.jpg)" 
                emailHref="mailto:zeljko.mihoci@signaco.si" 
                email="zeljko.mihoci@signaco.si" 
                tel="+386 (0) 41 244 324" 
                telHref="tel:+386 (0) 41 244 324"
                tel2="+ 386 (0) 1 723 09 73" 
                telHref2="tel:+ 386 (0) 1 723 09 73" 
                tel3="+ 386 (0) 1 723 09 74" 
                telHref3="tel:+ 386 (0) 1 723 09 74" 
                name="Željko Mihoci" 
                title="Vodja proizvodnje"
                education=""
              />

              <TeamSelection 
                img="url(../assets/zaposleni/Majda.jpg)" 
                emailHref="mailto:majda.kavsek@signaco.si" 
                email="majda.kavsek@signaco.si" 
                tel="+386 (0) 41 686 205" 
                telHref="tel:+386 (0) 41 686 205"
                tel2="+ 386 (0) 1 723 09 73" 
                telHref2="tel:+ 386 (0) 1 723 09 73" 
                tel3="+ 386 (0) 1 723 09 74" 
                telHref3="tel:+ 386 (0) 1 723 09 74" 
                name="Majda Kavšek" 
                title="Komerciala"
                education="dipl. inž. tehnol. prom."
              />

              <TeamSelection 
                img="url(../assets/zaposleni/Branko.jpg)" 
                emailHref="mailto:grafika@signaco.si" 
                email="grafika@signaco.si" 
                tel="+386 (0) 51 342 387" 
                telHref="tel:+386 (0) 51 342 387"
                tel2="+ 386 (0) 1 723 09 73" 
                telHref2="tel:+ 386 (0) 1 723 09 73" 
                tel3="+ 386 (0) 1 723 09 74" 
                telHref3="tel:+ 386 (0) 1 723 09 74" 
                name="Branko Tkavc" 
                title="Grafična priprava in elaborati"
                education="dipl. dizajner"
              />

              <TeamSelection 
                img="url(../assets/zaposleni/Bostjan.jpg)" 
                emailHref="" 
                email="" 
                tel="" 
                telHref=""
                tel2="+ 386 (0) 1 723 09 73" 
                telHref2="tel:+ 386 (0) 1 723 09 73" 
                tel3="+ 386 (0) 1 723 09 74" 
                telHref3="tel:+ 386 (0) 1 723 09 74" 
                name="Boštjan Zorman" 
                title="Proizvodnja"
                education=""
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const styles = StyleSheet.create({
   wrapper:{
      maxWidth: 1000,
      margin: "0px auto",
      paddingTop:10,
      paddingBottom:50,
      width: "calc(100% - 100px)",
      '@media (max-width: 580px)': {
        width: "calc(100% - 60px)",
      },
      '@media (max-width: 340px)': {
        width: "calc(100% - 40px)",
      },
   },
   innerWrapper:{
      maxWidth: 920,
   },
   container:{
    margin: "0 auto",
    positon: "relative",
    width: "100%",
  },
  title:{
    paddingTop:40,
    fontSize: 40,
    fontWeight: "300",
    color: "#838383",
    marginBottom: 50,
   
    '@media (max-width: 400px)': {
    marginBottom: 40,
    fontSize: 30
    },
    '@media (max-width: 360px)': {
    width: "90%",
    margin: "0 auto",
    marginBottom: 40
    },
  },
  contacts:{
    width: "100%",
    display: "flex",
    margin:"0 auto",
    '@media (max-width: 660px)': {
      flexDirection:"column",
    },
  },
  contactsInfo:{
    width: "50%",
    '@media (max-width: 660px)': {
      width: "100%",
    },
  },
  
  contacts_form:{

  },
  contactsInfo_1:{
    marginBottom: 80,
    '@media (max-width: 860px)': {
      marginBottom: 55,
    },
    '@media (max-width: 660px)': {
      marginBottom:25,
    },
  },
  contactsMail:{
    display:"block",
    textDecoration:"none",
    fontSize: 22,
    letterSpacing:0.9,
    color: "#595959",
    fontWeight:400,
    marginBottom: 25,
    '@media (max-width: 760px)': {
      fontSize: 20,
    },
  },
  contactsPhone:{
    fontSize: 22,
    letterSpacing:0.9,
    fontWeight:400,
    color: "#595959",
    '@media (max-width: 760px)': {
      fontSize: 20,
    },
  },
  contactsInfo_2:{
    fontSize: 16,
    fontWeight: "300",
    color: "#595959",
    marginBottom: 30,
    '@media (max-width: 760px)': {
      fontSize: 14,
    },
  },
  contactsFax:{
    marginBottom: 20,
    letterSpacing:0.6,
  },
  contactsOther:{
    marginBottom: 5,
    letterSpacing:0.6,
  },
  contactsInfo_map:{
    display: "flex",
    flexFlow: "row nowrap",
    alignItems: "center"
  },
  img:{
    display: "inline",
    marginRight: 10,
  },
  contactsInfo_map_text:{
    fontSize: 16,
    fontWeight: "300",
    color: "#707070",
    display: "inline",
    letterSpacing:0.6,
    '@media (max-width: 760px)': {
      fontSize: 14,
    },
  },
  displayTrue:{
    display:"none",
  },
  displayFalse:{
    display:"block",
  },
  left:{
    justifyContent:"space-between",
  },
  center:{
    justifyContent:"center",
  },
  flexWrapper:{
    display:"flex",
    flexWrap:"wrap",
    '@media (max-width: 800px)': {
      justifyContent:"space-between",
    },
    '@media (max-width: 700px)': {
      justifyContent:"flex-start",
    },
    '@media (max-width: 580px)': {
      justifyContent:"space-between",
    },
  },
});

export default ContactPage;
