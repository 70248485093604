import autobind from 'core-decorators/lib/autobind';
import React from 'react';
import Navigation from './Navigation';


@autobind
class SideNavigation extends React.Component {
  
  render() {
    return (
    	
        <Navigation onClick={()=>this.props.onClick()} />
 
    );
  }
}


export default SideNavigation;
