import autobind from 'core-decorators/lib/autobind';
import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import Helmet from "react-helmet";

import Catalog from '../ProductsPages/productsComponents/Catalog.js';

@autobind
class VerticalSignalingPage extends React.Component {
  render() {
    return (
      <div className={css(styles.wrapper)}>
        <Helmet>
          <title>Prometni znaki - Signaco</title>
          <meta name="description" content="Podjetje za prodajo prometnih znakov, izdelujemo prometne znake, nosilne drogove, portale, sidra ter ostalo komunalno in urbano opremo, prometni znak"/>
        </Helmet>
        <h1 className={css(styles.title)}>
          vertikalna signalizacija - prometni znaki
        </h1>
        <Catalog
          title="Prometni znaki"
          text="S prometno signalizacijo se zagotavlja izvajanje prometnih pravil in ukrepov za varnost prometa ter se označuje prometna ureditev na cestah s prometnimi znaki in tablami."
          katalogHref="../../assets/katalogi/I_00_Prometnasign.pdf"
          imgSrc="../assets/katalogiImg/I_00_Prometnasign.png"

        />
        <Catalog
          title="Turistični in drugi obvestilni znaki"
          text="Turistična in druga obvestilna signalizacija je namenjena obveščanju udeležencev cestnega prometa o kulturnih, naravnih in turističnih znamenitostih ter drugih pomembnih ustanovah, objektih, subjektih in napravah."
          katalogHref="../../assets/katalogi/I_02_Turisticnasign.pdf"
          imgSrc="../assets/katalogiImg/I_02_Turisticnasign.png"
        />
        <Catalog
          title="Železniški znaki"
          text="Železniška signalizacija obsega signalne znake, ki se uporabljajo v železniškem prometu in obvestilne znake, ki se uporabljajo na železniških postajališčih in peronih."
          katalogHref="../../assets/katalogi/II_01_Zeleznice.pdf"
          imgSrc="../assets/katalogiImg/II_01_Zeleznice.png"
        />
        <Catalog
          title="Vodni znaki"
          text="Z vodno signalizacijo se ureja promet plovil na plovnih rekah in kanalih."
          katalogHref="../../assets/katalogi/II_02_Vodnepoti.pdf"
          imgSrc="../assets/katalogiImg/II_02_Vodnepoti.png"
        />
      </div>
    );
  }
}


const styles = StyleSheet.create({
	wrapper:{
		margin:"40px auto",
    maxWidth:1000,
    width:"calc(100% - 100px)",
    '@media (max-width: 580px)': {
      width: "calc(100% - 60px)",
    },
    '@media (max-width: 340px)': {
      width: "calc(100% - 40px)",
    },
	},
  title:{
    color: "#838383",
    fontSize: 40,
    fontWeight: 300,
    letterSpacing: 1.8,
    lineHeight: "56px",
  },
});

export default VerticalSignalingPage;
