import autobind from 'core-decorators/lib/autobind';
import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import Helmet from "react-helmet";

@autobind
class ProductionPage extends React.Component {

  render() {
    return (
      <div className={css(styles.wrapper)}>
        <Helmet>
          <title>Proizvodna hala - Signaco</title>
          <meta name="description" content="Podjetje za prodajo prometnih znakov, izdelujemo prometne znake, nosilne drogove, portale, sidra ter ostalo komunalno in urbano opremo, izdelava: prometni znaki"/>
        </Helmet>
        <div className={css(styles.innerWrapper)}>
          <h1 className={css(styles.title)}>
            proizvodna hala
          </h1>
          <div className={css(styles.text)}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent orci lorem, commodo molestie hendrerit vel, ultricies id lacus. In vestibulum sem ac dui rhoncus, sit amet venenatis ipsum dictum. Integer interdum neque eu imperdiet consectetur. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent orci lorem, commodo molestie hendrerit vel, ultricies id lacus. In vestibulum sem ac dui rhoncus, sit amet venenatis ipsum dictum. Integer interdum neque eu imperdiet consectetur. 
          </div>
          <div className={css(styles.text)}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent orci lorem, commodo molestie hendrerit vel, ultricies id lacus. In vestibulum sem ac dui rhoncus, sit amet venenatis ipsum dictum. Integer interdum neque eu imperdiet consectetur.
          </div>
          <div className={css(styles.img)} />
          <div className={css(styles.title2)}>
            tehnologije
          </div>
          <div className={css(styles.text)}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent orci lorem, commodo molestie hendrerit vel, ultricies id lacus. In vestibulum sem ac dui rhoncus, sit amet venenatis ipsum dictum. Integer interdum neque eu imperdiet consectetur. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent orci lorem, commodo molestie hendrerit vel, ultricies id lacus. In vestibulum sem ac dui rhoncus, sit amet venenatis ipsum dictum. Integer interdum neque eu imperdiet consectetur. 
          </div>
          <div className={css(styles.text)}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent orci lorem, commodo molestie hendrerit vel, ultricies id lacus. In vestibulum sem ac dui rhoncus, sit amet venenatis ipsum dictum. Integer interdum neque eu imperdiet consectetur.
          </div>
        </div>
      </div>
    );
  }
}


const styles = StyleSheet.create({
	wrapper:{
    margin:"40px auto",
    maxWidth:1000,
    width:"calc(100% - 100px)",
    '@media (max-width: 580px)': {
      width: "calc(100% - 60px)",
    },
    '@media (max-width: 340px)': {
      width: "calc(100% - 40px)",
    },
  },
  innerWrapper:{
    maxWidth:700,
  },
  title:{
    color: "#838383",
    fontSize: 40,
    fontWeight: 300,
    letterSpacing: 1.8,
    lineHeight: "56px",
  },
  title2:{
    color: "#838383",
    fontSize: 40,
    fontWeight: 300,
    letterSpacing: 1.8,
    lineHeight: "56px",
    marginTop:30,
  },
  text:{
    marginTop:20,
    color: "#595959",
    fontSize: 12,
    lineHeight: "18px",
    marginLeft:30,
    '@media (max-width: 800px)': {
      marginLeft: 0,
    },
  },
  img:{
    marginLeft:30,
    background:"#F1F1F1",
    height:300,
    marginTop:50,
    width:"calc(100% - 30px)",
    '@media (max-width: 800px)': {
      marginLeft: 0,
    },
  },
});

export default ProductionPage;
