import autobind from 'core-decorators/lib/autobind';
import React from 'react';
import { StyleSheet, css } from 'aphrodite';

@autobind
class Catalog extends React.Component {

  render() {
    return (
      <div className={css(styles.wrapper)}>
        <div className={css(styles.innerWrapper)}>
          
          <div>
            <h2 className={css(styles.title)}>
              {this.props.title}
            </h2>
            <p className={css(styles.text)}>
              {this.props.text}
              <p dangerouslySetInnerHTML={{__html: this.props.dodatniText}} />
            </p>
            <div className={css(styles.buttonsWrapper)}>
              <a href={this.props.katalogHref} rel="noopener noreferrer" target="_blank" className={css(styles.buttonWrapper)}>
                <img alt="prometni znaki katalog signaco" src="../assets/icons/svg/buttonCatalog.svg" className={css(styles.buttonImg)} />
                <div className={css(styles.buttonText)}>
                  KATALOG
                </div>
              </a>
              {/*<a className={css(styles.buttonWrapper)}>
                <img alt="" src="../assets/icons/svg/buttonCatalog.svg" className={css(styles.buttonImg)} />
                <div className={css(styles.buttonText)}>
                  Montaža
                </div>
              </a>
              <a className={css(styles.buttonWrapper)}>
                <img alt="" src="../assets/icons/svg/buttonCatalog.svg" className={css(styles.buttonImg)}/>
                <div className={css(styles.buttonText)}>
                  Vzdrževanje
                </div>
              </a>*/}
            </div>
          </div>
          <a className={css(styles.imgWrapper)} href={this.props.katalogHref} rel="noopener noreferrer" target="_blank">
            <img alt={"prometni znaki "+this.props.imgSrc} src={this.props.imgSrc} className={css(styles.img)} />
          </a>
        </div>
      </div>
    );
  }
}


const styles = StyleSheet.create({
	wrapper:{
		margin:"60px auto",
    maxWidth:1100,
    width:"calc(100% - 40px)",
    '@media (max-width: 800px)': {
      width:"100%",
    },
	},
  innerWrapper:{
    maxWidth:800,
    display:"flex",
    alignItems:"center",
    justifyContent:"space-between",
    '@media (max-width: 500px)': {
      alignItems:"flex-start",
    },
  },
  imgWrapper:{
    textDecoration:"none",
    display:"block",
    transition:"0.3s",
    cursor:"pointer",
    position:"relative",
    marginLeft:50,
    ":hover":{
      transform:"scale(1.05)",
    },
    '@media (max-width: 500px)': {
      marginLeft:30,
    },
    '@media (max-width: 413px)': {
      display:"none",
    },
  },
  img:{
    width:150,
    borderRadius:17,
    '@media (max-width: 540px)': {
      width:120,
    },
  },
  title:{
    color: "#595959",
    fontSize: 25,
    fontWeight: 600,
    letterSpacing:0.7,
    lineHeight: "32px",
    '@media (max-width: 470px)': {
      fontSize: 18,
      lineHeight: "25px",
    },
  },
  text:{
    marginTop:30,
    marginBottom:30,
    fontSize: 17,
    lineHeight:1.4,
    color: "#707070",
  },
  buttonsWrapper:{
    maxWidth:500,
    display:"flex",
    
  },
  buttonWrapper:{
    textDecoration:"none",
    display:"flex",
    marginRight:40,
    marginTop:30,
    alignItems:"center",
    justifyContent: 'space-between',
    cursor:"pointer",
    transition:"0.2s",
    ":hover":{
      transform:"scale(1.05)",
    },
    '@media (max-width: 413px)': {
      marginTop:20,
    },
  },
  buttonImg:{
    width:30,
    marginRight:10,
  },
  buttonText:{
    textDecoration:"none",
    color: "#595959",
    fontSize: 14,
    fontWeight: 400,
  },

});

export default Catalog;
