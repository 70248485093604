import autobind from 'core-decorators/lib/autobind';
import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import ReactDOM from 'react-dom';

@autobind
class AboutSelection extends React.Component {
  props: Props;
  state = {
    hover: false,
    transition: false,
  };
  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll, { passive: true })
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }


  handleScroll() {
    if(window.innerWidth<500){
      var top = ReactDOM.findDOMNode(this).getBoundingClientRect().top;
      // console.log(top);
      // console.log(top, window.pageYOffset, window.innerHeight);
      // if(){

      // }
      if(top<window.innerHeight*0.5 && top !== 0){
        if(this.state.hover !== true){
          this.setState({transition: true});
          setTimeout(() => this.setState({ hover:true}), 150);
          setTimeout(() => this.setState({ transition: false}), 300);
        }
      } else {
        if(this.state.hover !== false){
          this.setState({transition: true});
          setTimeout(() => this.setState({ hover:false}), 150);
          setTimeout(() => this.setState({ transition: false}), 300);
        }
      }
    }
  }
  

  render() {
    const hoverState = this.state.hover ? styles.onHover : styles.offHover;
    const transition = this.state.transition ? styles.transition : null;
    return (
      <div
        id={this.props.title}
        onMouseEnter={()=> {
          this.setState({transition: true});
          setTimeout(() => this.setState({ hover:true}), 150);
          setTimeout(() => this.setState({ transition: false}), 300);
        }} 
        onClick={()=> {
          setTimeout(() => this.setState({ hover:true}), 150);
        }}
        onMouseLeave={()=> {
          this.setState({transition: true});
          setTimeout(() => this.setState({ hover:false}), 150);
          setTimeout(() => this.setState({ transition: false}), 300);
        }}
        className={css(styles.wrapper, hoverState, transition)}
      >
        {this.state.hover ?
          <p 
            dangerouslySetInnerHTML={{__html: this.props.text}}
            className={css(styles.infoText1)} 
          />
        :
          <p 
            dangerouslySetInnerHTML={{__html: this.props.title}}
            className={css(styles.infoText2)}
          />
        }
      </div>
    );
  }
}

const transition = {
    '0%': { opacity: 1, },
    '50%': { opacity: 0.2, },
    '100%': { opacity: 1, },
};

const styles = StyleSheet.create({
  wrapper:{
    maxWidth: 750,
    borderRadius: 56,
    display: "flex",
    justifyContent: "center",
    marginBottom: 20,
    alignItems:"center",
    position:"relative",
    '@media (max-width: 700px)': {
      borderRadius: 76,
    },
    '@media (max-width: 500px)': {
      borderRadius: 40,
    },

  },
  onHover:{
    height: 112,
    border: "0px solid #4CAF50",
    background: "linear-gradient(221.82deg, #B4EC51 0%, #39A269 83.52%, #29986C 94.47%, #29986C 94.47%, #23946D 98.85%, #21936D 100%)",
    '@media (max-width: 700px)': {
      height: 152,
    },
    '@media (max-width: 500px)': {
      height: 202,
    },
    '@media (max-width: 370px)': {
      height: 222,
    },
  },
  offHover:{
    height: 110,
    border: "1px solid #4CAF50",
    background: "linear-gradient(221.82deg, #fff 0%, #fff 100%)",
    '@media (max-width: 700px)': {
      height: 150,
    },
    '@media (max-width: 500px)': {
      height: 200,
    },
    '@media (max-width: 370px)': {
      height: 220,
    },
  },
  infoText1:{
    width: "calc(100% - 100px)",
    margin: "0 auto",
    fontSize: 14,
    color: "#FFF",
    fontWeight: "300",
    padding: "25 0",
    letterSpacing: 1.2,
    '@media (max-width: 500px)': {
       width: "calc(100% - 70px)",
    },
    '@media (max-width: 370px)': {
      width: "calc(100% - 50px)",
    },
  },

  infoText2:{
    width: "calc(100% - 100px)",
    margin: "0 auto",
    fontSize: 24,
    color: "#4CAF50",
    fontWeight: "300",
    padding: "35 0",
    letterSpacing: 1.08,
  },
  transition: {
    animationName: transition,
    animationDuration: "0.3s",
    animationTimingFunction: "ease-in-out",
  }

});

export default AboutSelection;
