import autobind from 'core-decorators/lib/autobind';
import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import Helmet from "react-helmet";

import Catalog from '../ProductsPages/productsComponents/Catalog.js';

@autobind
class OtherEquipmentPage extends React.Component {

  render() {
    return (
      <div className={css(styles.wrapper)}>
        <Helmet>
          <title>Druga oprema - Signaco</title>
          <meta name="description" content="Podjetje za prodajo prometnih znakov, izdelujemo prometne znake, nosilne drogove, portale, sidra ter ostalo komunalno in urbano opremo, prometna signalizacija"/>
        </Helmet>
        <h1 className={css(styles.title)}>
          druga oprema
        </h1>
        <Catalog
          title="Oprema vozil"
          text=""
          katalogHref="../../assets/katalogi/VIII_Opremavozil.pdf"
          imgSrc="../assets/katalogiImg/VIII_Opremavozil.png"
        />
        <Catalog
          title="Označevanje vozil"
          text="v pripravi"
          katalogHref="../../assets/katalogiImg/katalogVpripravi.jpg"
          imgSrc="../assets/katalogiImg/katalogVpripravi.jpg"
        />
        <Catalog
          title="Označevanje plovil"
          text="v pripravi"
          katalogHref="../../assets/katalogiImg/katalogVpripravi.jpg"
          imgSrc="../assets/katalogiImg/katalogVpripravi.jpg"
        />
      </div>
    );
  }
}


const styles = StyleSheet.create({
  wrapper:{
    margin:"40px auto",
    maxWidth:1000,
    width:"calc(100% - 100px)",
    '@media (max-width: 580px)': {
      width: "calc(100% - 60px)",
    },
    '@media (max-width: 340px)': {
      width: "calc(100% - 40px)",
    },
  },
  title:{
    color: "#838383",
    fontSize: 40,
    fontWeight: 300,
    letterSpacing: 1.8,
    lineHeight: "56px",
  },
});

export default OtherEquipmentPage;
