import autobind from 'core-decorators/lib/autobind';
import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import withRouter from "react-router/withRouter";


@autobind
class Navigation extends React.Component {
  state = {
    linkState: [false, false, false, false, false, false, false, 
                false, false, false, false, false, false,
                false],
    lastId: 1,
    subLinks: false,
  };

  componentWillMount(){

  }


  handleClick(e){
    var array = this.state.linkState;
    array[this.state.lastId] = false;
    array[e] = true;
    if(2 <= e && e <= 10){
      array[2] = true;
    } else {
      array[2] = false;
    }
    this.setState({linkState: [...array], lastId: e })
  }
  subLinksChange(){
    this.setState({subLinks: !this.state.subLinks});
  }
  subLinksOpen(){
    this.setState({subLinks: true});
  }
  subLinksClose(){
    this.setState({subLinks: false});
  }


  render() {
    const linkState1 = this.state.linkState[1] ? styles.linkActive : null;
    const linkState2 = this.state.linkState[2] ? styles.linkActive : null;
    const linkState3 = this.state.linkState[3] ? styles.subLinkActive : null;
    const linkState4 = this.state.linkState[4] ? styles.subLinkActive : null;
    const linkState5 = this.state.linkState[5] ? styles.subLinkActive : null;
    const linkState6 = this.state.linkState[6] ? styles.subLinkActive : null;
    const linkState7 = this.state.linkState[7] ? styles.subLinkActive : null;
    const linkState8 = this.state.linkState[8] ? styles.subLinkActive : null;
    const linkState9 = this.state.linkState[9] ? styles.subLinkActive : null;
    const linkState10 = this.state.linkState[10] ? styles.subLinkActive : null;
    const linkState11 = this.state.linkState[11] ? styles.linkActive : null;
    const linkState12 = this.state.linkState[12] ? styles.linkActive : null;
    const linkState13 = this.state.linkState[13] ? styles.linkActive : null;



    const subLinksWrapperState = this.state.subLinks ? styles.subLinksWrapperOpen : styles.subLinksWrapperClose;
        

    return (
      <div className={css(styles.wrapper)}>

        <div className={css(styles.firstChild)}>
          <div className={css(styles.imgWrapper)}>
            <img alt="prometni znaki signaco" src="../assets/icons/signacoLogo.png" className={css(styles.img)} />
          </div>
          <div className={css(styles.textWrapper)}>
            <p className={css(styles.text1)}>SIGNACO d.o.o.</p>
            <p className={css(styles.text2)}>Podjetje za proizvodnjo prometnih in neprometnih znakov ter obvestil</p>
          </div>
          <div onClick={()=>{if(this.props.onClick)this.props.onClick()}} className={css(styles.linksWrapper)}>
            <div 
            className={css(styles.link, linkState1)} 
              onClick={()=> {
                this.subLinksClose();
                var id=1;
                this.handleClick(id);
                window.scrollTo(0,0);
                if(this.props.onClose)this.props.onClose();
                setTimeout(() => {
                  this.props.history.push("/");
                }, 250);
              }}
            
            >
              DOMOV
            </div>
            <div
              className={css(styles.link, linkState2)} 
              onClick={()=> {
                this.subLinksChange();
                var id=2;
                this.handleClick(id);
                window.scrollTo(0,0);
                
                setTimeout(() => {
                  this.props.history.push("/znaki/vertikalna-signalizacija");
                }, 250)
              }}
            >
              IZDELKI
            </div>
            <div className={css(styles.subLinksWrapper, subLinksWrapperState)}>
              <div 
                className={css(styles.subLink, linkState3)} 
                onClick={()=> {
                  var id=3;
                  this.handleClick(id);
                  window.scrollTo(0,0);
                  if(this.props.onClose)this.props.onClose();
                  setTimeout(() => {
                    this.props.history.push("/znaki/vertikalna-signalizacija");
                  }, 250)
                }}
              >
                Prometni znaki
              </div>
              <div 
                className={css(styles.subLink, linkState4)} 
                onClick={()=> {
                  var id=4;
                  this.handleClick(id);
                  window.scrollTo(0,0);
                  if(this.props.onClose)this.props.onClose();
                  setTimeout(() => {
                    this.props.history.push("/znaki/horizontalna-signalizacija");
                  }, 250)
                }}
              >
                Horizontalna signalizacija
              </div>
              <div 
                className={css(styles.subLink, linkState5)} 
                onClick={()=> {
                  var id=5;
                  this.handleClick(id);
                  window.scrollTo(0,0);
                  if(this.props.onClose)this.props.onClose();
                  setTimeout(() => {
                    this.props.history.push("/znaki/ostala-signalizacija");
                  }, 250)
                }}
              >
                Ostala signalizacija
              </div>
              <div 
                className={css(styles.subLink, linkState6)} 
                onClick={()=> {
                  var id=6;
                  this.handleClick(id);
                  window.scrollTo(0,0);
                  if(this.props.onClose)this.props.onClose();
                  setTimeout(() => {
                    this.props.history.push("/znaki/prometna-oprema");
                  }, 250)
                }}
              >
                Prometna oprema
              </div>
              <div 
                className={css(styles.subLink, linkState7)} 
                onClick={()=> {
                  var id=7;
                  this.handleClick(id);
                  window.scrollTo(0,0);
                  if(this.props.onClose)this.props.onClose();
                  setTimeout(() => {
                    this.props.history.push("/znaki/druga-oprema");
                  }, 250)
                }}
              >
                Druga oprema
              </div>
              <div 
                className={css(styles.subLink, linkState8)} 
                onClick={()=> {
                  var id=8;
                  this.handleClick(id);
                  window.scrollTo(0,0);
                  if(this.props.onClose)this.props.onClose();
                  setTimeout(() => {
                    this.props.history.push("/znaki/katalogi");
                  }, 250)
                }}
              >
                Katalogi
              </div>
              <div 
                className={css(styles.subLink, linkState9)} 
                onClick={()=> {
                  var id=9;
                  this.handleClick(id);
                  window.scrollTo(0,0);
                  if(this.props.onClose)this.props.onClose();
                  setTimeout(() => {
                    this.props.history.push("/znaki/navodila");
                  }, 250)
                }}
              >
                Navodila
              </div>
              <div 
                className={css(styles.subLink, linkState10)} 
                onClick={()=> {
                  var id=10;
                  this.handleClick(id);
                  window.scrollTo(0,0);
                  if(this.props.onClose)this.props.onClose();
                  setTimeout(() => {
                    this.props.history.push("/znaki/galerija-izdelkov");
                  }, 250)
                }}
              >
                Galerija izdelkov
              </div>
              
            </div>
            {/*<div
              className={css(styles.link, linkState10)} 
              onClick={()=> {
                this.subLinksClose();
                var id=10;
                this.handleClick(id);
                window.scrollTo(0,0);
                if(this.props.onClose)this.props.onClose();
                setTimeout(() => {
                  this.props.history.push("/proizvodnja");
                }, 250)
              }}
            >
              PROIZVODNJA
            </div>*/}
            <div
              className={css(styles.link, linkState11)} 
              onClick={()=> {
                this.subLinksClose();
                var id=11;
                this.handleClick(id);
                window.scrollTo(0,0);
                if(this.props.onClose)this.props.onClose();
                setTimeout(() => {
                  this.props.history.push("/o-podjetju");
                }, 250)
              }}
            >
              O PODJETJU
            </div>
            <div
              className={css(styles.link, linkState12)} 
              onClick={()=> {
                this.subLinksClose();
                var id=12;
                this.handleClick(id);
                window.scrollTo(0,0);
                if(this.props.onClose)this.props.onClose();
                setTimeout(() => {
                  this.props.history.push("/kontakt");
                }, 250)
              }}
            >
              KONTAKT
            </div>
            <div
              className={css(styles.link, linkState13)} 
              onClick={()=> {
                this.subLinksClose();
                var id=13;
                this.handleClick(id);
                window.scrollTo(0,0);
                if(this.props.onClose)this.props.onClose();
                setTimeout(() => {
                  this.props.history.push("/novice");
                }, 250)
              }}
            >
              NOVICE
            </div>
          </div>
          <div className={css(styles.contactWrapper)}>
            <a className={css(styles.contact1)} onClick={()=>window.history.replaceState(null, null, window.location.origin+window.location.pathname+"?mail")} href="mailto:info@signaco.si">INFO@SIGNACO.SI
              <img className={css(styles.contact1Img)} alt="prometni znaki mail" src="../assets/icons/mail.png" />
            </a>
            <a onClick={()=>window.history.replaceState(null, null, window.location.origin+window.location.pathname+"?tel")} className={css(styles.contact2)} href="tel:+386 (0) 1 723 09 73">01 723 09 73
              <img className={css(styles.contact2Img)} alt="prometni znaki telefon" src="../assets/icons/phone.png" />
            </a>
            <a className={css(styles.contact3)} href="/kontakt">PONUDBA
              <img className={css(styles.contact3Img)} alt="prometni znaki naprej" src="../assets/icons/next.png" />
            </a>
            <a className={css(styles.contact4)} href="https://mojznak.signaco.si/">ZNAK PO MERI
              <img className={css(styles.contact4Img)} alt="prometni znaki naprej" src="../assets/icons/next.png" />
            </a>
            <a className={css(styles.contact5)} href="https://narocevalnik.signaco.si/">NAROČEVALNIK
              <img className={css(styles.contact5Img)} alt="prometni znaki naprej" src="../assets/icons/next.png" />
            </a>

          </div>
        </div>
        <div className={css(styles.secondChild)}>
          <div className={css(styles.rights)}>© 2021 Signaco d.o.o</div>
          <a href="https://mtoffice.net" className={css(styles.authorWrapper)}>
            <div className={css(styles.author1)}>IZDELAVA</div>
            <div className={css(styles.author2)} />
          </a>
        </div>
      </div>
    );
  }
}


const styles = StyleSheet.create({
  wrapper:{
    width:280,
    position:"relative",
    height:"auto",
    zIndex:1000,
    minHeight:"100%",
    display:"flex",
    flexDirection:"column",
    boxShadow: "0 0 20px 0 rgba(89,89,89,0.2)",
  },


  firstChild:{
    width:280,
  },


  imgWrapper:{
    padding:35,
    paddingTop:60,
    borderLeft:"10px solid #4CAF50",
  },
  img:{
    width:100,
  },


  textWrapper:{
    padding:35,
    paddingBottom:60,
    borderLeft:"10px solid #4CAF50",
  },
  text1:{
    color: "#595959",
    fontSize: 11,
    fontWeight: 600,
    marginBottom:10,
  },
  text2:{
    color: "#595959",
    fontSize: 10,
    width:164,
    letterSpacing:0.5,
    lineHeight: "18px",
  },


  linksWrapper:{

  },
  subLinksWrapper:{
    borderLeft:"10px solid #4CAF50",
    position:"relative",
  },
  subLinksWrapperOpen:{
    transition:"0.4s",
    paddingTop:10,
    paddingBottom:10,

    height:280.727,
  },
  subLinksWrapperClose:{
    transition:"0.4s",
    paddingTop:0,
    paddingBottom:0,
    height:0,
  },
  link:{
    background:"#fff",
    position:"relative",
    borderLeft:"10px solid #4CAF50",
    letterSpacing:0.14,
    textDecoration:"none",
    color:"#595959",
    padding: 13,
    paddingLeft:35,
    cursor:"pointer",
    fontSize:14,
    fontWeight:400,
    webkitTransition:"0.2s linear",
    transition:"0.1s linear",
    display:"block",
    ":hover":{
      color:"#595959",
      fontWeight:700,
      fontSize:15,
      borderLeft:"10px solid #B0DAB2",
    },
  },
  linkActive:{
    color:"#595959",
    fontWeight:700,
    fontSize:15,
    borderLeft:"10px solid #B0DAB2",
  },

  subLink:{
    letterSpacing:0.5,
    textDecoration:"none",
    color:"#595959",
    padding: 10,
    paddingLeft:52,
    cursor:"pointer",
    fontSize:12,
    fontWeight:400,
    webkitTransition:"0.2s linear",
    transition:"0.1s linear",
    display:"block",
    ":hover":{
      color:"#000",
      
      fontWeight:600,
    },
  },
  subLinkActive:{
    color:"#000",
    fontWeight:600,
  },


  contactWrapper:{
    position:"relative",
    paddingTop:70,
    width:220,
    background:"#fff",
    borderLeft:"10px solid #4CAF50",
  },
  contact1:{
    display:"flex",
    textDecoration:"none",
    marginLeft:35,
    borderRadius:20,
    paddingLeft:10,
    alignItems:"center",
    background:"#9B9B9B",
    color: "#fff",
    fontSize: 16,
    fontWeight: 500,
    height:40,
    width:190,
    transition:"0.3s",
    justifyContent:"center",
    letterSpacing: 0.5,
    lineHeight: "32px",
    ":hover":{
      transform:"scale(1.05)",
    }
  },
  contact1Img:{
    width:27,
    paddingLeft:20,
  },
  contact2:{
    marginTop:20,
    display:"flex",
    textDecoration:"none",
    marginLeft:35,
    borderRadius:20,
    paddingLeft:10,
    alignItems:"center",
    background:"#9B9B9B",
    color: "#fff",
    fontSize: 16,
    transition:"0.3s",
    fontWeight: 500,
    height:40,
    width:190,
    justifyContent:"center",
    letterSpacing: 0.5,
    lineHeight: "32px",
    ":hover":{
      transform:"scale(1.05)",
    }
  },
  contact2Img:{
    width:27,
    paddingLeft:62,
  },

  contact3:{
    marginTop:20,
    display:"flex",
    textDecoration:"none",
    marginLeft:35,
    borderRadius:20,
    paddingLeft:10,
    alignItems:"center",
    background:"#4CAF50",
    color: "#fff",
    fontSize: 16,
    transition:"0.3s",
    fontWeight: 500,
    height:40,
    width:190,
    justifyContent:"center",
    letterSpacing: 0.5,
    lineHeight: "32px",
    ":hover":{
      transform:"scale(1.05)",
    }
  },
  contact3Img:{
    width:27,
    paddingLeft:80,
  },
  contact4:{
    marginTop:20,
    display:"flex",
    textDecoration:"none",
    marginLeft:35,
    borderRadius:20,
    paddingLeft:10,
    alignItems:"center",
    background:"#4CAF50",
    color: "#fff",
    fontSize: 16,
    transition:"0.3s",
    fontWeight: 500,
    height:40,
    width:190,
    justifyContent:"center",
    letterSpacing: 0.5,
    lineHeight: "32px",
    ":hover":{
      transform:"scale(1.05)",
    }
  },
  contact4Img:{
    width:27,
    paddingLeft:46,
  },

 contact5:{
    marginTop:20,
    display:"flex",
    textDecoration:"none",
    marginLeft:35,
    borderRadius:20,
    paddingLeft:10,
    alignItems:"center",
    background:"#4CAF50",
    color: "#fff",
    fontSize: 16,
    transition:"0.3s",
    fontWeight: 500,
    height:40,
    width:190,
    justifyContent:"center",
    letterSpacing: 0.5,
    lineHeight: "32px",
    ":hover":{
      transform:"scale(1.05)",
    }
  },
  contact5Img:{
    width:27,
    paddingLeft:40,
  },





  secondChild:{
    width:220,
    paddingLeft:45,

    borderLeft:"10px solid #4CAF50",
    flexGrow:100,
    display:"flex",
    alignItems:"flex-start",
    justifyContent:"flex-end",

    flexDirection:"column",
  },
  rights:{
    color: "#595959",
    fontSize: 10,
    paddingTop:40,
  },
  authorWrapper:{
    display:"flex",
    paddingBottom:30,
    paddingTop:20,
    alignItems:"flex-end",
    justifyContent:"flex-end",
    textDecoration:"none",
    cursor:"pointer",
  },
  author1:{
    color:"#595959",
    textDecoration:"none",
    fontSize: 11,
    lineHeight:0.7,
  },
  author2:{
    width:60,
    height:11,
    marginLeft:10,
    backgroundImage:"url(../assets/icons/MtofficeSiv.png)",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "50%",
    backgroundSize:"contain",
    ":hover":{
      backgroundImage:"url(../assets/icons/logoBlack.png)",
    }
  },
  
  

});

export default withRouter(Navigation);
