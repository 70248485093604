import autobind from 'core-decorators/lib/autobind';
import React from 'react';
import { StyleSheet, css } from 'aphrodite';


@autobind
class CookiePopup extends React.Component {
  props: Props;
  state = {
    display:true,
    delay:true,
  };

  
  hide(){
    this.setState({display:false});
    setTimeout(() => this.setState({ delay: false}), 600);
    var now = new Date();
    var time = now.getTime();
    time += 20000000000;
    now.setTime(time);
    document.cookie = 
    'agreeCookies=yes' + 
    '; expires=' + now.toUTCString() + 
    '; path=/';
  }

  componentDidMount(){
    if(document.cookie.replace(/(?:(?:^|.*;\s*)agreeCookies\s*=\s*([^;]*).*$)|^.*$/, "$1") === "yes"){
      this.setState({ delay: false});
    }
  }


  render() {
  const displayState = this.state.display ? styles.displayON : styles.displayOFF;
    const displayOFFState = this.state.delay ? null : styles.displayOFFOFF;
    
    return (
      <div className={css(styles.wrapper, displayState, displayOFFState)}>
        <div>ČE UPORABLJATE TO STRAN, SOGLAŠATE K UPORABI PIŠKOTOV.</div>
        {/*<a href="../assets/Politika_piskotkov.pdf" target="_blank" className={css(styles.button)}>ŽELITE IZVEDETI VEČ</a>*/}
        <img alt="close" onClick={this.hide} className={css(styles.close)} src="../assets/icons/close.png" />
      </div>
    );
  }
}


const styles = StyleSheet.create({
  wrapper:{
    margin:"0 auto",
    width:"100%",
    position:"fixed",
    boxShadow: "0 0 20px 0 rgba(89,89,89,0.2)",
    zIndex:"1000000",
    bottom:0,
    color:"#000",
    display:"flex",
    textAlign:"center",
    justifyContent:"center",
    alignItems:"center",
    background:"#fff",
    left:0,
    height:65,
    transition:"0.5s",
    '@media (max-width: 780px)': {
      height:80,
      flexDirection:"column",
      paddingLeft:60,
      paddingRight:60,
      width:"calc(100% - 120px)",
    },
    '@media (max-width: 540px)': { 
      height:100,
    },
    '@media (max-width: 350px)': { 
      fontSize:14,
    },
  },
  displayON:{
    opacity:1,
  },
  displayOFF:{
    opacity:0,
  },
  displayOFFOFF:{
    display:"none",
  },
  button:{
    backgroundColor: "",
    borderBottom: "#fff 2px solid",
    padding: "2 2",
    color: "#fff",
    textDecoration:"none",
    marginLeft:30,
    position: "relative",
    transition: "all .2s",
    cursor:"pointer",
    '@media (max-width: 780px)': {
      marginLeft:0,
      marginTop:7,
    },
  },
  close:{
    position:"fixed",
    width:20,
    bottom:22,
    right:30,
    cursor:"pointer",
    zIndex:1000,
    '@media (max-width: 780px)': {
      bottom:29,
      right:20,
    },
    '@media (max-width: 540px)': { 
      bottom:39,
    },
  },
  
});

export default CookiePopup;
