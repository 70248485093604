import autobind from 'core-decorators/lib/autobind';
import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import Helmet from "react-helmet";


import NewPost from "../selections/NewPost.js";

// import jsonp from 'jsonp';

// import PhotoGrid from 'components/PhotoGrid';

@autobind
class NewsPage extends React.Component {
 //  props: Props;
 //  state = {
 //    response:{},
 //    media:{},
 //    tag:{},

 //  };

 //  componentDidMount(){
 //  this.state.response = new Promise(resolve => {
 //    jsonp('https://api.instagram.com/v1/users/self/?access_token=608151399.1445bf4.c6c27c1376d849c5a717e5b9040cded6', (error, response) => {
 //      this.setState({ response: response });
 //      console.log(this.state.response);
 //    });
 //  });
 //  this.state.media = new Promise(resolve => {
 //    jsonp('https://api.instagram.com/v1/users/self/media/recent/?access_token=608151399.1445bf4.c6c27c1376d849c5a717e5b9040cded6', (error, response2) => {
 //      this.setState({ media: response2 });
 //      console.log(this.state.media);
 //      console.log(this.state.media.data[2].id);
 //      this.state.tag = new Promise(resolve2 => {
 //        jsonp('https://api.instagram.com/v1/media/'+this.state.media.data[2].id+'/comments?access_token=608151399.1445bf4.c6c27c1376d849c5a717e5b9040cded6', (error2, response2) => {
 //          this.setState({ tag: response2 });
 //          console.log(this.state.tag);
 //        });
 //      });
 //    });
 //  });
 //  // next_max_id value as max_tag_id
  
 // }
 

  render() {
    return (
      <div className={css(styles.wrapper)}>
        <Helmet>
          <title>Novice - Signaco</title>
          <meta name="description" content="Podjetje za prodajo prometnih znakov, izdelujemo prometne znake, nosilne drogove, portale, sidra ter ostalo komunalno in urbano opremo, izdelava: prometni znaki"/>
        </Helmet>
      
        
        <NewPost 
          title="Prenovljena spletna stran signaco.si" 
          date="maj 2018" 
          text="Veseli nas, da vam lahko predstavimo posodobljeno spletno stran signaco.si. V prihodnjih nekaj tednih bomo spletno stran posodabljali z dodatno tekstovno in slikovno vsebino, zato vas prosimo za potrpežljivost. Na spletni strani bomo uvedli tudi nekaj novosti o katerih vas bomo sproti obveščali." 
          author="Aleš Babnik, direktor" 
        />

        <NewPost 
          title="Signaco d.o.o. v medijih" 
          date="november 2018" 
          text="Poglejte si več o čem smo govorili na spletni strani www.24ur.com, tako da kliknete na sliko ali <a style='color:#707070;' href='https://www.24ur.com/novice/slovenija/tehnicni-postopek-izdelave-prometnih-znakov.html' target='_blank'>tukaj</a>.<div><a style='display:block' href='https://www.24ur.com/novice/slovenija/tehnicni-postopek-izdelave-prometnih-znakov.html' target='_blank'><img style='maxWidth:600px;width:100%;margin-top:10px;cursor:pointer;' alt='novica2' src='../assets/pictures/novica2.PNG' /><a></div>" 
          author="Aleš Babnik, direktor" 
        />
        
        
      </div>
    );
  }
}


const styles = StyleSheet.create({
	wrapper:{
    maxWidth: 1000,
    margin: "0px auto",
    paddingTop:10,
    paddingBottom:50,
    width: "calc(100% - 100px)",
    '@media (max-width: 580px)': {
      width: "calc(100% - 60px)",
    },
    '@media (max-width: 340px)': {
      width: "calc(100% - 40px)",
    },
  },
  

});

export default NewsPage;
